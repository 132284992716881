import { pullAt } from 'lodash';

// Admin loginId

export const PERSONIO_REQUIRED_FIELDS = ['preName', 'surName', 'salutation', 'emailBusiness'];

export const ADMIN_ACTIVITY_TYPE = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};

export const DEV_ADMIN = 'admin_develop';
export const PRODUCTION_ADMIN = 'admin_prod';

export const ROLE_ADMIN = 'ROLE_ADMIN';

// Employee in a company that will manage their own profile and bAV journey.
export const ROLE_EMPLOYEE = 'ROLE_EMPLOYEE';

// Administrator on the client side, managing roles for the client account across all companies.
// Has all privileges of an HR manager, i.e. can manage employees across all companies within an organization.
export const ROLE_ORG_ADMIN = 'ROLE_ORG_ADMIN';

// Individual tasked with managing employees through the bAV lifecycle from the company side.
export const ROLE_BAV_MANAGER = 'ROLE_BAV_MANAGER';

// Project manager assigned to a company on Degura side.
// Should be able to “see and do everything” in the company (employees, documents). Will not interact directly with employee.
// ---- Not used for now
// export const PROJECT_MANAGER = 'ROLE_PROJECT_MANAGER'

// Assigned to multiple employees in a company as their direct contact.
// Will interact with employee. Manages everything on employee level for those employees assigned.
export const ROLE_KEY_ACCOUNT = 'ROLE_KEY_ACCOUNT';

export const ALL_ROLES = [ROLE_EMPLOYEE, ROLE_BAV_MANAGER, ROLE_KEY_ACCOUNT, ROLE_ADMIN];

export const HR_ROLES = [ROLE_BAV_MANAGER, ROLE_KEY_ACCOUNT];

export const SALUTATION_MR = 'SALUTATION_MR';
export const SALUTATION_MRS = 'SALUTATION_MRS';
export const SALUTATION_DIV = 'SALUTATION_DIV';

export const SALUTATION_TRANSLATION_MAP = {
  [SALUTATION_MR]: 'formLabelSalutationMale',
  [SALUTATION_MRS]: 'formLabelSalutationFemale',
  [SALUTATION_DIV]: 'formLabelSalutationDiverse',
};

export const ORG_TYPE_EXISTING = 'ORG_TYPE_EXISTING';
export const ORG_TYPE_NEW = 'ORG_TYPE_NEW';

export const HEALTH_INSURANCE_PRIVATE = 'INSURANCE_PRIVATE';
export const HEALTH_INSURANCE_STATUTORY = 'INSURANCE_STATUTORY';

export const HEALTH_INSURANCE_TRANSLATION_MAP = {
  [HEALTH_INSURANCE_PRIVATE]: 'formLabelHealthInsurancePrivate',
  [HEALTH_INSURANCE_STATUTORY]: 'formLabelHealthInsuranceStatutory',
};

export function createHealthInsuranceSelection() {
  return Object.entries(HEALTH_INSURANCE_TRANSLATION_MAP).map((entry) => {
    return { value: entry[0], label: this.$t(entry[1]) };
  });
}

export function createChildAllowanceSelection() {
  return [
    { value: 0, label: 0 },
    { value: 0.5, label: 0.5 },
    { value: 1, label: 1 },
    { value: 1.5, label: 1.5 },
    { value: 2, label: 2 },
    { value: 2.5, label: 2.5 },
    { value: 3, label: 3 },
    { value: 3.5, label: 3.5 },
    { value: 4, label: 4 },
    { value: 4.5, label: 4.5 },
    { value: 5, label: 5 },
  ];
}

export const TAX_CLASS_1 = 'TAX_CLASS_1';
export const TAX_CLASS_2 = 'TAX_CLASS_2';
export const TAX_CLASS_3 = 'TAX_CLASS_3';
export const TAX_CLASS_4 = 'TAX_CLASS_4';
export const TAX_CLASS_5 = 'TAX_CLASS_5';
export const TAX_CLASS_6 = 'TAX_CLASS_6';

export const TAX_CLASS_TRANSLATION_MAP = {
  [TAX_CLASS_1]: 'formLabelTaxClass1',
  [TAX_CLASS_2]: 'formLabelTaxClass2',
  [TAX_CLASS_3]: 'formLabelTaxClass3',
  [TAX_CLASS_4]: 'formLabelTaxClass4',
  [TAX_CLASS_5]: 'formLabelTaxClass5',
  [TAX_CLASS_6]: 'formLabelTaxClass6',
};

export function createTaxClassSelection() {
  return Object.entries(TAX_CLASS_TRANSLATION_MAP).map((entry) => {
    return { value: entry[0], label: this.$t(entry[1]) };
  });
}

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

export const EMP_STATUS_DEFAULT = 'EMP_STATUS_DEFAULT';
export const EMP_STATUS_TERMINATED = 'EMP_STATUS_TERMINATED';
export const EMP_STATUS_PARENTAL_LEAVE = 'EMP_STATUS_PARENTAL_LEAVE';
export const EMP_STATUS_SICK_LEAVE = 'EMP_STATUS_SICK_LEAVE';

// Initial status after creation in the system
export const CONTRACT_ONBOARDED = 'CONTRACT_ONBOARDED';

// Employee has requested an offer via CTA on BAV Info or MyBAV page.
export const CONTRACT_OFFER_REQUESTED = 'CONTRACT_OFFER_REQUESTED';

// Employee has either
// a) requested to close a contract via CTA on BAV Info or MyBAV page or
// b) accepted an offer that was made by Degura KA after it had been requested
export const CONTRACT_OFFER_ACCEPTED = 'CONTRACT_OFFER_ACCEPTED';

// A contract application has been submitted to the insurer for the employee's request
export const CONTRACT_APPLICATION = 'CONTRACT_APPLICATION';

// Employee has signed a contract via Degura.
export const CONTRACT_SIGNED = 'CONTRACT_SIGNED';

// Employee has an existing contract
export const CONTRACT_EXISTING = 'CONTRACT_EXISTING';

// Employee has signaled that he/she is not interested in closing a contract by either
// a) completing opt-out flow via CTA on myBAV or BAV info page
// b) declining an offer made
export const CONTRACT_OPTED_OUT = 'CONTRACT_OPTED_OUT';

// Employee contract has been suspended due to leaving company/retirement/death
export const CONTRACT_SUSPENDED = 'CONTRACT_SUSPENDED';

export const CONTRACT_TRANSLATION_MAP = {
  [CONTRACT_ONBOARDED]: 'companyPensionStatusOnboarded',
  [CONTRACT_OFFER_REQUESTED]: 'companyPensionStatusOfferRequested',
  [CONTRACT_OFFER_ACCEPTED]: 'companyPensionStatusOfferAccepted',
  [CONTRACT_APPLICATION]: 'companyPensionStatusApplication',
  [CONTRACT_SIGNED]: 'companyPensionStatusSigned',
  [CONTRACT_EXISTING]: 'companyPensionStatusOldContract',
  [CONTRACT_OPTED_OUT]: 'companyPensionStatusOfferOptedOut',
  [CONTRACT_SUSPENDED]: 'companyPensionStatusSuspended',
};

export function createContractSelection() {
  return Object.entries(CONTRACT_TRANSLATION_MAP).map((entry) => {
    return { value: entry[0], label: this.$t(entry[1]) };
  });
}

export const CONTRACT_STATUS_ICON_MAP = {
  [CONTRACT_EXISTING]: 'DIconPaperRoll',
  [CONTRACT_ONBOARDED]: 'DIconOnboarded',
  [CONTRACT_OFFER_REQUESTED]: 'DIconContract',
  [CONTRACT_OFFER_ACCEPTED]: 'DIconAccepted',
  [CONTRACT_APPLICATION]: 'DIconSubmitted',
  [CONTRACT_SIGNED]: 'DIconBav',
  [CONTRACT_OPTED_OUT]: 'DIconDeclined',
  [CONTRACT_SUSPENDED]: 'DIconSuspended',
};

export const DOCUMENT_TYPE_ICON_MAP = {
  'application/msword': 'DIconDoc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DIconDoc',
  'image/jpeg': 'DIconJpg',
  'application/vnd.oasis.opendocument.spreadsheet': 'DIconXls',
  'application/vnd.oasis.opendocument.text': 'DIconDoc',
  'image/png': 'DIconPng',
  'application/pdf': 'DIconPdf',
  'application/vnd.ms-excel': 'DIconXls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'DIconXls',
};

export const DOC_BALANCE_REPORT = 'DOC_BALANCE_REPORT';
export const DOC_CHECKLIST = 'DOC_CHECKLIST';
export const DOC_CLIENT_INFO = 'DOC_CLIENT_INFO';
export const DOC_COLLECTIVE_AGREEMENT = 'DOC_COLLECTIVE_AGREEMENT';
export const DOC_CONSULTATION = 'DOC_CONSULTATION';
export const DOC_CONSUMER_INFO = 'DOC_CONSUMER_INFO';
export const DOC_CONVERSION_AGREEMENT = 'DOC_CONVERSION_AGREEMENT';
export const DOC_FORM = 'DOC_FORM';
export const DOC_INCIDENT = 'DOC_INCIDENT';
export const DOC_LIST_REPORT = 'DOC_LIST_REPORT';
export const DOC_MANDATE = 'DOC_MANDATE';
export const DOC_OFFER = 'DOC_OFFER';
export const DOC_OTHER = 'DOC_OTHER';
export const DOC_PENSION_SCHEME = 'DOC_PENSION_SCHEME';
export const DOC_POLICY = 'DOC_POLICY';
export const DOC_PROCESSING_AGREEMENT = 'DOC_PROCESSING_AGREEMENT';
export const DOC_PRODUCT_INFO = 'DOC_PRODUCT_INFO';
export const DOC_TERMS = 'DOC_TERMS';
export const DOC_WAIVER = 'DOC_WAIVER';
export const DOC_SECURITY_SCAN_PENDING = 'PENDING';
export const DOC_SECURITY_SCAN_CLEAN = 'CLEAN';
export const DOC_SECURITY_SCAN_INFECTED = 'INFECTED';
export const DOC_REQUEST = 'DOC_REQUEST';
export const DOC_CUSTOMER_INFO = 'DOC_CUSTOMER_INFO';

export const ALL_CATEGORIES = [
  DOC_BALANCE_REPORT,
  DOC_CHECKLIST,
  DOC_CLIENT_INFO,
  DOC_COLLECTIVE_AGREEMENT,
  DOC_CONSULTATION,
  DOC_CONSUMER_INFO,
  DOC_CONVERSION_AGREEMENT,
  DOC_FORM,
  DOC_INCIDENT,
  DOC_LIST_REPORT,
  DOC_MANDATE,
  DOC_OFFER,
  DOC_OTHER,
  DOC_PENSION_SCHEME,
  DOC_POLICY,
  DOC_PROCESSING_AGREEMENT,
  DOC_PRODUCT_INFO,
  DOC_TERMS,
  DOC_WAIVER,
  DOC_CUSTOMER_INFO,
];

export const COMPANY_CATEGORIES = [
  DOC_CHECKLIST,
  DOC_CLIENT_INFO,
  DOC_COLLECTIVE_AGREEMENT,
  DOC_LIST_REPORT,
  DOC_MANDATE,
  DOC_OTHER,
  DOC_PENSION_SCHEME,
  DOC_PROCESSING_AGREEMENT,
];

export const EMPLOYEE_CATEGORIES = [
  DOC_CUSTOMER_INFO,
  DOC_REQUEST,
  DOC_OFFER,
  DOC_TERMS,
  DOC_CONSULTATION,
  DOC_FORM,
  DOC_PRODUCT_INFO,
  DOC_POLICY,
  DOC_BALANCE_REPORT,
  DOC_CONVERSION_AGREEMENT,
  DOC_CONSUMER_INFO,
  DOC_WAIVER,
  DOC_INCIDENT,
  DOC_OTHER,
];

export const DOC_SECURITY_SCAN_STATUS = [
  DOC_SECURITY_SCAN_PENDING,
  DOC_SECURITY_SCAN_CLEAN,
  DOC_SECURITY_SCAN_INFECTED,
];

export const DOCUMENT_SECURITY_SCAN_STATUS_TRANSLATION_MAP = {
  [DOC_SECURITY_SCAN_PENDING]: 'DOC_SECURITY_SCAN_PENDING',
  [DOC_SECURITY_SCAN_CLEAN]: 'DOC_SECURITY_SCAN_CLEAN',
  [DOC_SECURITY_SCAN_INFECTED]: 'DOC_SECURITY_SCAN_INFECTED',
};

export const CATEGORY_TRANSLATION_MAP = {
  [DOC_BALANCE_REPORT]: 'DOC_BALANCE_REPORT',
  [DOC_CHECKLIST]: 'DOC_CHECKLIST',
  [DOC_CLIENT_INFO]: 'DOC_CLIENT_INFO',
  [DOC_COLLECTIVE_AGREEMENT]: 'DOC_COLLECTIVE_AGREEMENT',
  [DOC_CONSULTATION]: 'DOC_CONSULTATION',
  [DOC_CONSUMER_INFO]: 'DOC_CONSUMER_INFO',
  [DOC_CONVERSION_AGREEMENT]: 'DOC_CONVERSION_AGREEMENT',
  [DOC_FORM]: 'DOC_FORM',
  [DOC_INCIDENT]: 'DOC_INCIDENT',
  [DOC_LIST_REPORT]: 'DOC_LIST_REPORT',
  [DOC_MANDATE]: 'DOC_MANDATE',
  [DOC_OFFER]: 'DOC_OFFER',
  [DOC_OTHER]: 'DOC_OTHER',
  [DOC_PENSION_SCHEME]: 'DOC_PENSION_SCHEME',
  [DOC_POLICY]: 'DOC_POLICY',
  [DOC_PROCESSING_AGREEMENT]: 'DOC_PROCESSING_AGREEMENT',
  [DOC_PRODUCT_INFO]: 'DOC_PRODUCT_INFO',
  [DOC_TERMS]: 'DOC_TERMS',
  [DOC_WAIVER]: 'DOC_WAIVER',
  [DOC_REQUEST]: 'DOC_REQUEST',
  [DOC_CUSTOMER_INFO]: 'DOC_CUSTOMER_INFO',
};

export const BENEFICIARY_LEGAL_SUCCESSION = 'BENEFICIARY_LEGAL_SUCCESSION';
export const BENEFICIARY_PARTNER = 'BENEFICIARY_PARTNER';
export const BENEFICIARY_DEATH_GRANT = 'BENEFICIARY_DEATH_GRANT';
export const BENEFICIARY_NONE = 'BENEFICIARY_NONE';

export const BENEFICIARY_TRANSLATION_MAP = {
  [BENEFICIARY_LEGAL_SUCCESSION]: 'BENEFICIARY_LEGAL_SUCCESSION',
  [BENEFICIARY_PARTNER]: 'BENEFICIARY_PARTNER',
  [BENEFICIARY_DEATH_GRANT]: 'BENEFICIARY_DEATH_GRANT',
  [BENEFICIARY_NONE]: 'BENEFICIARY_NONE',
};

export function createBeneficiarySelection() {
  return Object.entries(BENEFICIARY_TRANSLATION_MAP).map((entry) => {
    return { value: entry[0], label: this.$t(entry[1]) };
  });
}

export const PHONE = 'PHONE';
export const EMAIL = 'EMAIL';
export const PHONE_EMAIL = 'PHONE_EMAIL';

export const CONSULTATION_TYPE_REQUEST_OFFER = 'REQUEST_OFFER';
export const CONSULTATION_TYPE_ACCEPT_OFFER = 'ACCEPT_OFFER';
export const CONSULTATION_TYPE_DECLINE = 'DECLINE';

export const LANGUAGE_FORMAL = 'LANGUAGE_FORMAL';
export const LANGUAGE_INFORMAL = 'LANGUAGE_INFORMAL';

export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const CTA_TO_EVENT_MAP = {
  [CONSULTATION_TYPE_REQUEST_OFFER]: 'cta-request',
  [CONSULTATION_TYPE_ACCEPT_OFFER]: 'cta-accept',
  [CONSULTATION_TYPE_DECLINE]: 'cta-decline',
  chat: 'cta-chat',
};

export const DEFAULT_COUNTRY = 'DE';

export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_DELETED = 'DELETED';

export const DATA_IMPORT_MANUAL = 'MANUAL';
export const DATA_IMPORT_PERSONIO = 'PERSONIO';

export function createCountrySelection(locale) {
  if (locale === undefined) return [];

  let getDisplayName;
  if (Intl.DisplayNames === undefined) {
    getDisplayName = (e) => e[1].native;
  } else {
    const displayNames = new Intl.DisplayNames([locale], { type: 'region' });
    getDisplayName = (e) => displayNames.of(e[0]);
  }

  const { countries } = require('countries-list');

  const options = Object.entries(countries).map((n) => ({ value: n[0], text: getDisplayName(n) }));

  // remove from somewhere and prepend on top
  const removed = pullAt(
    options,
    options.findIndex((e) => e.value === 'DE'),
  );
  options.unshift(removed[0]);

  return options;
}

export const PORTAL_ATTRIBUTES_MAP = {
  employeeId: 'ID',
  companyId: 'Company ID',
  salutation: 'Salutation',
  preName: 'First name',
  surName: 'Last name',
  city: 'City',
  emailPersonal: 'Personal Email',
  personalNumber: 'Mobile number',
  status: 'Status',
  postalCode: 'Postal code',
  streetAddress: 'Street',
  country: 'Country',
  dateOfBirth: 'DOB',
  cityOfBirth: 'City',
  countryOfBirth: 'Birth Country',
  emailBusiness: 'Business email',
  phoneNumber: 'Phone Number',
  nationality: 'Nationality',
  taxResidence: 'Tax residence',
  occupation: 'Occupation',
  taxClass: 'Tax class',
  taxClassFactor: 'TCF',
  capitalFormationContribution: 'CFC',
  childAllowance: 'Chld Allowance',
  churchTax: 'Church tax',
  healthInsuranceType: 'TBC',
  healthInsurancePrivateContribution: 'TBC',
  careInsuranceContribution: 'TBC',
  incomeTaxContribution: 'TBC',
  grossIncome: 'TBC',
  initialContractStatus: 'TBC',
  languageCode: 'Language',
  languageForm: 'TBC',
};

export const MAX_FILE_SIZE = 10; // MB

// TECH-1166
export const JIRA_FIELDS = {
  NAME: 'customfield_10107',
};
export const JIRA_HIDDEN_FIELD_IDS = [JIRA_FIELDS.NAME];

export const EXAMPLE_CALCULATION_METHODS = {
  DEFERRED: 'DEFERRED',
  TOTAL: 'TOTAL',
};

export const EXAMPLE_CALCULATION_TEMPLATES = [
  {
    name: 'Paul Pension',
    dateOfBirth: '1996-01-01',
    taxClass: 'TAX_CLASS_1',
    childAllowance: 0,
    churchTax: false,
    grossWage: '3300',
    netContribution: '100',
    employerContribution: { type: 'variable', value: '0.2' },
    noCompanyPension: {
      netWage: '2165.06',
      socialSecurityDues: '665.78',
      taxDues: '469.16',
    },
    withCompanyPension: {
      calculationMethod: EXAMPLE_CALCULATION_METHODS.TOTAL,
      netWage: '2065.06',
      socialSecurityDues: '628.68',
      taxDues: '422.41',
      payments: {
        paymentEmployeeContribution: '100',
        paymentEmployerSubsidy: '0.2',
        savingsInDues: '83.85',
      },
      contractLifetime: {
        duration: '42',
        totalPaidInOwn: '50400',
        sharePaidInOwn: '0.4071',
        totalPaidInOther: '73392',
        sharePaidInOther: '0.5929',
        capitalPaymentGuaranteed: '123792',
        projectedAnnualReturn: '0.04',
        capitalPaymentProjected: '238530',
      },
    },
    exampleFile: {
      de: '../../assets/example-calculations/files/Pension_Paul.pdf',
      en: '../../assets/example-calculations/files/Pension_Paul.pdf',
    },
  },

  {
    name: 'Renate Rente',
    dateOfBirth: '1991-01-01',
    taxClass: 'TAX_CLASS_3',
    childAllowance: 0.5,
    churchTax: false,
    grossWage: '5000',
    netContribution: '150',
    employerContribution: {
      type: 'variable',
      value: '0.2',
    },
    noCompanyPension: {
      netWage: '3438.73',
      socialSecurityDues: '980.94',
      taxDues: '580.33',
    },
    withCompanyPension: {
      calculationMethod: EXAMPLE_CALCULATION_METHODS.TOTAL,
      netWage: '3288.73',
      socialSecurityDues: '953.25',
      taxDues: '521.66',
      payments: {
        paymentEmployeeContribution: '150.0',
        paymentEmployerContribution: '68.17',
        paymentEmployerSubsidy: '0.2',
        savingsInDues: '86.36',
      },
      contractLifetime: {
        duration: '37',
        totalPaidInOwn: '66600.0',
        sharePaidInOwn: '0.4926',
        totalPaidInOther: '68611.0',
        sharePaidInOther: '0.5074',
        capitalPaymentGuaranteed: '135211.0',
        projectedAnnualReturn: '0.04',
        capitalPaymentProjected: '237235.0',
      },
    },
    exampleFile: {
      de: '',
      en: '',
    },
  },

  {
    name: 'Ruth Ruhestand',
    dateOfBirth: '1986-01-01',
    taxClass: 'TAX_CLASS_4',
    childAllowance: 1,
    churchTax: true,
    grossWage: '7000',
    netContribution: '200',
    employerContribution: {
      type: 'variable',
      value: '0.2',
    },
    noCompanyPension: {
      netWage: '3919.66',
      socialSecurityDues: '735.0',
      taxDues: '1889.4',
    },
    withCompanyPension: {
      calculationMethod: EXAMPLE_CALCULATION_METHODS.TOTAL,
      netWage: '3719.66',
      socialSecurityDues: '712.34',
      taxDues: '1693.35',
      payments: {
        paymentEmployeeContribution: '200.0',
        paymentEmployerContribution: '68.17',
        paymentEmployerSubsidy: '0.2',
        savingsInDues: '218.71',
      },
      contractLifetime: {
        duration: '32',
        totalPaidInOwn: '76800.0',
        sharePaidInOwn: '0.4108',
        totalPaidInOther: '110162.0',
        sharePaidInOther: '0.5892',
        capitalPaymentGuaranteed: '186962.0',
        projectedAnnualReturn: '0.04',
        capitalPaymentProjected: '299580.0',
      },
    },
    exampleFile: {
      de: '',
      en: '',
    },
  },
];
