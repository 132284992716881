import axios from '@/plugins/axios';

export const acceptTerms = (id) => {
  return axios.put(`/employees/${id}/accepted-at`);
};

export const getEmployeeById = (id) => {
  return axios.get(`/employees/${id}`);
};

export const createEmployee = (payload) => {
  return axios.post('/employees', payload);
};

export const deleteEmployee = (id) => {
  return axios.delete(`/employees/${id}`);
};

export const getContractByEmployeeId = async (id) => {
  const response = await axios.get(`/contracts/employees/${id}`);

  return response.data[0];
};

export const updateEmployeePersonalData = (id, payload) => {
  return axios.put(`/employees/${id}/personal`, payload);
};

export const updateEmployeeTaxData = (id, payload) => {
  return axios.put(`/employees/${id}/tax`, payload);
};

export const updateRootCompany = (id, companyId, orgId) => {
  return axios.put(`/employees/${id}/updatecompany`, { companyId, orgId });
};

export const employeeOptOut = async (employeeId, payload) => {
  const contractResponse = await getContractByEmployeeId(employeeId);

  return axios.post(`/contracts/${contractResponse.id}/opt-out`, payload);
};

export const updateEmployeeContractStatus = async (employeeId, contractStatus) => {
  const contractResponse = await getContractByEmployeeId(employeeId);

  return axios.post(`/contracts/${contractResponse.id}/update-status`, { contractStatus });
};

export const requestContract = async (employeeId, payload, type = 'request-contract') => {
  const contractResponse = await getContractByEmployeeId(employeeId);

  return axios.post(`/contracts/${contractResponse.id}/${type}`, payload);
};
