export default {
  agb: 'Allgemeinen Geschäftsbedingungen',
  dpp: 'Datenschutzerklärung',
  acceptAgbDppStart: 'Ich habe die ',
  acceptAgbDppAnd: ' und die ',
  acceptAgbDppEnd: ' gelesen und stimme diesen hiermit zu.',
  // Forbidden page
  forbiddenPageTitle: 'Entschuldigung, aber es fehlen die Zugriffsrechte zu diesem Inhalt.',
  forbiddenPageBtn: 'Zurück',
  // Not found page
  notFoundPageTitle: 'Uuups, diese Seite konnten wir leider nicht finden.',
  notFoundBtnLogged: 'Zum Login',
  notFoundBtnNotLogged: 'Zum Login',

  setupBavDirectly: 'bAV direkt einrichten',
  setupBavDirectlyConfirm:
    'Hiermit bestätige ich, dass eine betriebliche Altersvorsorge (bAV) über meinen Arbeitgeber für mich beantragt werden soll. Ich nehme zur Kenntnis, dass ich meinen Versicherungsschein und die zugehörigen Dokumente spätestens zum Versicherungsbeginn erhalte.',
  setupBavDirectlyRadio: 'Es soll direkt eine bAV für mich eingerichtet werden',
  setupBavWithOfferRadio: 'Ich möchte ein unverbindliches Angebot anfordern',
  healthInsuranceProvider: 'Krankenkasse',

  switchLanguageToolTip: 'Sprache ändern',
  profileToolTip: 'Profil',
  // Admin Calculations
  deleteCalculationsConfirmation: 'Sie sind im Begriff {count} Berechnungen zu entfernen. Sind Sie sich sicher?',
  // Admin - employees
  labelSearchEmployee: 'Mitarbeiter suchen',
  employeePageNavTitle: 'Arbeitnehmer',
  sharedDocumentsPageNavTitle: 'Geteilte Dokumente',
  companyDocumentsPageNavTitle: 'Unternehmensdokumente',
  adminEmployeesPageTitle: '⁣Degura bAV-Portal - Verwaltung - Mitarbeiter',
  adminCompaniesPageTitle: '⁣Degura bAV-Portal - Verwaltung - Firmendokumente',
  uploadDocument: 'Dokument hochladen',
  deleteEmployeeConfirmation: 'Sie sind im Begriff {count} Mitarbeiter zu entfernen. Sind Sie sich sicher?',
  deleteCompanyConfirmation: 'Sie sind im Begriff "{name}" Unternehmen zu entfernen. Sind Sie sich sicher?',
  // Profile
  CONTACT_METHOD: 'Bevorzugte Kontaktmethode',
  PHONE: 'Telefon',
  EMAIL: 'E-Mail',
  PHONE_EMAIL: 'Alle',
  labelEmployeeStartDate: 'Beschäftigungsbeginn',
  // Bav info
  bavInfoNextToCalculations: 'Weiter zu den Rechenbeispielen',
  bavInfoNextToProviders: 'Weiter zu den Versicherungspartnern',
  bavInfoBackToCalculations: 'Zurück zu den Rechenbeispielen',
  bavInfoBackToGeneral: 'Zurück zu dem Allgemeinen der bAV',
  bavInfoNextToCalculationsMobile: 'Rechenbeispielen',
  bavInfoNextToProvidersMobile: 'Versicherungspartnern',
  bavInfoBackToGeneralMobile: 'Allgemeines',
  bavInfoBackToCalculationsMobile: 'Rechenbeispielen',
  // My BAV
  myBavCheckDataConfirm: 'Bestätigung',
  myDocumentStatusInfoTitle: 'Statusanzeige des Dokuments',
  myDocumentStatusInfo1: 'Ausstehend - Dokument wird noch gescannt',
  myDocumentStatusInfo2: 'OK - Dokument wurde erfolgreich hochgeladen',
  myDocumentStatusInfo3: 'Fehlgeschlagen - Dokument konnte nicht hochgeladen werden',
  myBavNetContributionLabel: 'bAV-Nettobeitrag in €',
  stepperButtonContinue1: 'Weiter zu den finanziellen Angaben',
  stepperButtonContinue2: 'Weiter zu den bAV-Angaben',
  stepperButtonContinue3: 'Weiter zu der Datenprüfung',
  stepperButtonContinue4: 'Unverbindliches Angebot anfordern',
  stepperButtonContinue4Direct: 'bAV direkt einrichten lassen',
  stepperButtonBack2: 'Zurück zu den persönlichen Angaben',
  stepperButtonBack3: 'Zurück zu den finanziellen Angaben',
  stepperButtonBack4: 'Zurück zu den bAV-Angaben',
  myBavCreateRequest: 'Neue Anfrage erstellen',
  myBavRequestContractChange: 'Vertragsänderung beantragen',
  myBavDetailsTitle: 'Vertragsdetails',
  myBavDocumentsDescription:
    'In diesem Bereich finden Sie alle wichtigen Dokumente, die von Ihrer persönlichen Beraterin oder Ihrer Personalabteilung für Sie hochgeladen wurden. Für Dokumente, die Sie in der Vergangenheit hochgeladen haben, wird angezeigt, ob diese mit Ihrer persönlichen Beraterin oder Ihrer Personalabteilung geteilt wurden.',
  myBavStatus: 'Status bAV:',
  myBavSearchNFilter: 'Suchen & Filtern',
  myBavUploadDocumentText1: 'Datei(en) in das Feld ziehen oder Dateien durchsuchen (maximal 5 Dateien) ',
  myBavRecycleBin: 'Dokumente im Papierkorb',
  myBavActive: 'Aktive Dokumente',
  myBavActions: 'Aktionen',
  myBavRecycleBinConfirmation: 'Möchten Sie das ausgewählte Dokument wirklich in den Papierkorb verschieben?',
  myBavPermanentDeletionConfirmation: 'Möchten Sie das ausgewählte Dokument wirklich dauerhaft löschen?',
  myBavRecycleBinConfirmationMultiple:
    'Sind Sie sicher, dass Sie alle Dokumente in den Papierkorb verschieben möchten?',
  myBavPermanentDeletionConfirmationMultiple: 'Möchten Sie wirklich alle ausgewählten Dokumente endgültig löschen?',
  myBavUploadModalTitle: 'Dokument hochladen',
  myBavUploadModalDocLabel: 'Dokument',
  myBavUploadModalCategoryLabel: 'Kategorie festlegen*',
  myBavUploadModalShare: 'Dokument für Personalabteilung freigeben',
  myBavUploadModalShareInfo:
    'Alle hochgeladenen Dokumente werden automatisch mit Ihrer persönlichen Beraterin der Degura GmbH geteilt. Sollten Sie Dokumente zusätzlich mit Ihrer zuständigen HR-Abteilung teilen wollen, können Sie dies durch Aktivieren des Buttons veranlassen.',
  myBavEditModalTitle: 'Dokument bearbeiten',
  myBavEditModalSliderLabel: 'Personalabteilung',
  myBavNoResults: 'Es gibt leider keine Ergebnisse',
  myBavStatusFilter: 'Nach Status filtern',
  myBavCategoryFilter: 'Nach Kategorie filtern',
  myBavDeleteSelected: 'Auswahl löschen',
  myBavPersonalAdvisor: 'Persönlicher Berater',
  myBavPersonalAdvisorF: 'Persönlicher Beraterin',
  myBavDocumentsPerPage: 'Dokumente pro Seite: ',
  myBavSearchLabel: 'Dokument suchen',
  myBavStepperConfirm: 'Ich bestätige die Richtigkeit meiner Daten und stimme einer Kontaktaufnahme zu.',
  myBavKeyAccManagerF: 'Key Account Managerin',
  myBavKeyAccManager: 'Key Account Manager',
  // Common
  browse: 'durchsuchen',
  remove: 'Entfernen',
  verify: 'Verifizieren',
  edit: 'Bearbeiten',
  delete: 'Löschen',
  download: 'Herunterladen',
  upload: 'Laden',
  selected: 'Ausgewählt',
  overview: 'Überblick',
  yes: 'Ja',
  no: 'Nein',
  itemsPerPage: 'Objekte pro Seite: ',
  doYouWishToContinue: 'Möchten Sie fortfahren?',
  doWishToContinueWithoutSaving: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie fortfahren, ohne zu speichern?',
  // Settings
  settingsChangePasswordTitle: 'In diesem Bereich haben Sie die Möglichkeit Ihr aktuelles Passwort zu ändern.',
  // Change Contract Status
  settingsChangeContractStatus: 'In diesem Bereich haben Sie die Möglichkeit Ihren aktuellen Vertragsstand zu ändern.',
  // 2fa
  twoFactorSuccessHeadline: 'Herzlichen Glückwunsch!',
  twoFactorSuccessMsg: 'Sie haben Ihre Zwei-Schritte-Authentifizierung erfolgreich eingerichtet.',
  twoFactorProblem: 'Sie haben Probleme den 2-Schritte-Login einzurichten?',
  twoFactorMethodInUseIntro:
    'Jedes Mal wenn Sie sich anmelden, erhalten Sie anschließend einen einmaligen Code auf diese Telefonnummer.',
  twoFactorMobileNumberLabel: 'Mobilnummer',
  numberVerify: 'Nummer bestätigen',
  codeVerify: 'Code bestätigen',
  // Validation
  requiredFieldError: 'Das ist ein Pflichtfeld',
  requiredFileError: 'Bitte eine Datei auswählen',
  emailIsNotValid: 'Bitte eine gültige E-Mail-Adresse eingeben',
  // Welcome Login
  welcomeTitle: '',
  // Outdated
  pageTitle2faSettings: 'Degura bAV-Portal - 2FA-Einstellungen',
  pageTitle2faSetup: '⁣Degura bAV-Portal - ⁣Zwei-Faktor-Authentifizierung',
  pageTitle2faVerification: 'Degura bAV-Portal - 2FA-Verifizierung',
  pageTitleAcceptPrivacyPolicy: '⁣Degura bAV-Portal - Datenschutzerklärung akzeptieren',
  pageTitleAddEmployee: '⁣Degura bAV-Portal - Mitarbeiter hinzufügen',
  pageTitleChangePassword: '⁣Degura bAV-Portal - Kennwort ändern',
  pageTitleConsultation: '⁣Degura bAV-Portal - Beratung',
  pageTitleEmployeeOverview: '⁣Degura bAV-Portal - Mitarbeiterübersicht',
  pageTitleFaq: '⁣Degura bAV-Portal - FAQ',
  pageTitleForgotPassword: '⁣Degura bAV-Portal - Passwort vergessen',
  pageTitleHomeEmployee: '⁣Degura-bAV Portal - Startseite',
  pageTitleImprint: '⁣Degura bAV Portal - Impressum',
  pageTitleLogin: '⁣Degura bAV-Portal - Login',
  pageTitleMyPension: '⁣Degura bAV-Portal - Meine bAV',
  pageTitlePlayground: '⁣Degura bAV-Portal - Spielplatz',
  pageTitlePrivacyPolicy: '⁣Degura bAV-Portal - Datenschutzerklärung',
  pageTitleSettings: '⁣Degura bAV-Portal - Einstellungen',
  pageTitleRegistrationSuccessful: '⁣Degura bAV-Portal - Erfolgreiche Registrierung',
  pageTitleConsultationDecline: '⁣Degura bAV-Portal - Beratung ablehnen',
  pageTitleConsultationOffer: '⁣Degura bAV-Portal - Beratungsangebot',
  pageTitleConsultationTotalContributionsGraphic: '⁣Degura bAV-Portal - Beratung',
  pageTitleConsultationValueDevelopmentGraphic: '⁣Degura bAV-Portal - Beratung',
  pageTitleCompanyDocuments: '⁣Degura bAV-Portal - Dokumente',
  pageTitleEmployeeDocuments: '⁣Degura bAV-Portal - Dokumente',
  pageTitleMyDocuments: '⁣Degura bAV-Portal - Meine Dokumente',
  pageTitleError404: '⁣Degura bAV-Portal - 404',
  pageTitleMyProfile: '⁣Degura bAV-Portal - Mein Profil',
  pageTitleUserProfile: '⁣Degura bAV-Portal - Profil',
  pageTitleWelcome: '⁣Degura bAV-Portal - Willkommen',
  pageTitleIndex: '⁣Degura bAV-Portal - Startseite',
  menuOverview: 'Mitarbeiterübersicht',
  menuConsultation: 'Beratung',
  menuEvents: 'Ereignisse',
  menuContractualDocuments: 'Auftragsunterlagen',
  menuMyPension: 'Meine bAV',
  menuDocuments: 'Dokumente',
  menuRoles: 'Rollen-Zuweisung',
  linkImprint: 'Impressum',
  linkPrivacy: 'Datenschutz',
  privacyPolicyLabel: 'Datenschutzvereinbarung',
  passwordNoticeLabel: 'Hier',
  siteTitle: 'Degura bAV-Portal',
  linkHeaderSettings: 'Einstellungen',
  linkHeaderSwitchRole: 'Rolle wechseln',
  linkHeaderSwitchRoleToEmployee: 'Zum Mitarbeiter-Profil wechseln',
  linkHeaderSwitchRoleToHR: 'Zum HR-Profil wechseln',
  linkHeaderRoleHR: 'HR Manager',
  linkHeaderRoleAM: 'Account manager',
  linkHeaderRoleEmployee: 'Mitarbeiter',
  linkHeaderSwitchLanguage: 'Switch to English',
  linkHeaderLogout: 'Abmelden',
  linkForgotPassword: 'Passwort vergessen?',
  linkGoBack: 'Zurückgehen',
  linkHere: 'hier',
  checkboxStayLoggedIn: 'Eingeloggt bleiben',
  checkboxAcceptPrivacyPolicy: 'Hiermit akzeptiere ich die {0}.',
  checkboxCheckAll: 'Alle Mitarbeiter auswählen',
  checkboxCheckAllLabel: 'Alle auswählen',
  checkboxCheckEmployee: 'Mitarbeiter auswählen',
  checkboxDecline:
    'Hiermit erkläre ich, dass ich auf eine bAV über meinen Arbeitgeber verzichte. Mir ist bewusst, dass ich meine Entscheidung jederzeit widerrufen und die bAV auch zu einem späteren Zeitpunkt bei meinem Arbeitgeber einreichen kann.',
  checkboxAccept:
    'Hiermit bestätige ich, dass eine betriebliche Altersvorsorge (bAV) über meinen Arbeitgeber für mich beantragt werden soll. Ich habe zur Kenntnis genommen, dass ich meinen Versicherungsschein und die zugehörigen Dokumente spätestens zum Versicherungsbeginn erhalte.',
  checkboxShareWithHR: 'Dokumente für Personalabteilung freigeben',
  toggleTextPasswordShow: 'Passwort zeigen',
  toggleTextPasswordHide: 'Passwort verbergen',
  toggleSortFirstNameUp: 'Vornamen von A bis Z sortieren',
  toggleSortFirstNameDown: 'Vornamen von Z bis A sortieren',
  toggleSortLastNameUp: 'Nachnamen von A bis Z sortieren',
  toggleSortLastNameDown: 'Nachnamen von Z bis A sortieren',
  toggleSortTitleUp: 'Anrede von A bis Z sortieren',
  toggleSortTitleDown: 'Anrede von Z bis A sortieren',
  toggleSortInsurerUp: 'Versicherer von A bis Z sortieren',
  toggleSortInsurerDown: 'Versicherer von Z bis A sortieren',
  toggleSortDocumentUp: 'Nach Dokumentenname von A bis Z sortieren',
  toggleSortDocumentDown: 'Nach Dokumentenname von Z bis A sortieren',
  toggleSortCategoryUp: 'Nach Kategorie von A bis Z sortieren',
  toggleSortCategoryDown: 'Nach Kategorie von Z bis A sortieren',
  toggleSortDateTimeUp: 'Nach Datum von A bis Z sortieren',
  toggleSortDateTimeDown: 'Nach Datum von Z bis A sortieren',
  altTextLogo: 'Logo von Degura',
  altTextLogoClient: 'Logo von {COMPANY}',
  altTextGradientGraphic: 'Blauer Hintergrund',
  altTextIconConsultantPhone: 'Telefonnummer Berater',
  altTextInfoIcon: 'Info',
  altTextCheckSingleDoc: 'Dokument auswählen',
  altTextDOCXIcon: 'DOCX-Datei',
  altTextDOCIcon: 'DOC-Datei',
  altTextXLSXIcon: 'XLSX-Datei',
  altTextXLSIcon: 'XLS-Datei',
  altTextPNGIcon: 'PNG-Datei',
  altTextJPGIcon: 'JPG-Datei',
  altTextPDFIcon: 'PDF-Datei',
  altTextUploadedPDFIcon: 'Hochgeladene PDF-Datei',
  altTextInProgressPDFIcon: 'Datei lädt hoch',
  altTextFailedUploadAlertIcon: 'Fehlgeschlagener Upload',
  altTextCompanyPensionIcon: 'Gewählter bAV-Beitrag',
  altTextReferencePersonIcon: 'Bezugsberechtigte Person',
  altTextDownloadIcon: 'Herunterladen',
  altTextErrorMessageIcon: 'Fehlermeldung',
  altTextCloseIcon: 'Fenster schließen',
  altTextReloadIcon: 'Neu laden versuchen',
  formLabelOptional: '(optional)',
  formLabelVideoCall: 'Videoberatung anfragen',
  formLabelStartChat: 'Chat starten',
  formLabelProfileSettings: 'Profil und Einstellungen',
  formLabelEmail: 'E-Mail',
  formLabelEmailLogin: 'E-Mail oder Benutzername',
  formPlaceholderEmail: 'E-Mail-Adresse eingeben',
  formLabelPassword: 'Passwort',
  formPlaceholderPassword: 'Passwort eingeben',
  formLabelNewPassword: 'Neues Passwort',
  formPlaceholderNewPassword: 'Neues Passwort eingeben',
  formLabelRepeatPassword: 'Passwort wiederholen',
  formPlaceholderRepeatPassword: 'Neues Passwort bestätigen',
  formLabelReset: 'Zurücksetzen',
  formLabelGoBack: 'Zurückgehen',
  formLabelEditIcon: 'Icon bearbeiten',
  formLabelEdit: 'Bearbeiten',
  formLabelUpload: 'Dokument teilen',
  formLabelUserSystemId: 'Benutzer ID-Nr.',
  formLabelLastName: 'Nachname',
  formLabelFirstName: 'Vorname',
  formLabelCompany: 'Unternehmen',
  formLabelSalutation: 'Anrede',
  formLabelSalutationFemale: 'Frau',
  formLabelSalutationMale: 'Herr',
  formLabelSalutationDiverse: 'Divers',
  formLabelPostalCode: 'PLZ',
  formLabelCity: 'Wohnort',
  formLabelStreetAddress: 'Straße & Hausnr.',
  formLabelCountry: 'Land',
  formPlaceholderCountry: 'Bitte Land wählen',
  formLabelTaxResidence: 'Steuerlicher Wohnsitz',
  formPlaceholderTaxResidence: 'Bitte steuerlichen Wohnsitz wählen',
  formLabelDateOfBirth: 'Geburtsdatum',
  formPlaceholderDateOfBirth: 'TT.MM.JJJJ',
  formLabelPlaceOfBirth: 'Geburtsort',
  formLabelCountryOfBirth: 'Geburtsland',
  formPlaceholderCountryOfBirth: 'Bitte Geburtsland wählen',
  formLabelEmailPersonal: 'E-Mail privat',
  formLabelEmailBusiness: 'E-Mail geschäftlich',
  formLabelPhone: 'Telefon',
  formLabelPersonalNumber: 'Personalnummer',
  formLabelNationality: 'Nationalität',
  formPlaceholderNationality: 'Bitte Nationalität wählen',
  formLabelProfession: 'Beruf',
  formLabelTaxClass: 'Steuerklasse',
  formPlaceholderTaxClass: 'Bitte Steuerklasse wählen',
  formContactPlaceholderEmail: 'Bsp: john.doe@gmail.com',
  formLabelFactor: 'Faktor',
  formLabelTaxClass1: 'I',
  formLabelTaxClass2: 'II',
  formLabelTaxClass3: 'III',
  formLabelTaxClass4: 'IV',
  formLabelTaxClass5: 'V',
  formLabelTaxClass6: 'VI',
  formLabelChildTaxAllowance: 'Kinderfreibetrag',
  formPlaceholderChildTaxAllowance: 'Bitte Kinderfreibetrag wählen',
  formLabelChurchTax: 'Kirchensteuer',
  formPlaceholderChurchTax: 'Bitte auswählen',
  formLabelYes: 'Ja',
  formLabelNo: 'Nein',
  formLabelNetContribution: 'Nettobeitrag zur bAV',
  formLabelEmployersContribution: 'Arbeitgeberzuschuss',
  formLabelHealthInsurance: 'Krankenversicherung',
  formPlaceholderHealthInsurance: 'Bitte wählen',
  formLabelHealthInsuranceStatutory: 'Gesetzlich',
  formLabelHealthInsurancePrivate: 'Privat',
  formLabelContribution: 'Beitragssatz',
  formLabelContributionPerc: 'Beitragssatz (in %)',
  formPlaceholderContribution: 'z.B. 15,7',
  formLabelTotalCont: 'Gesamtbeitrag PKV',
  formPlaceholderTotalCont: 'z.B. 600,00',
  formLabelCareInsurance: 'Gesamtbeitrag Pflegeversicherung',
  formPlaceholderCareInsurance: 'z.B. 250,00',
  formLabelTaxLevy: 'Lohnsteuerabgabe',
  formPlaceholderTaxLevy: 'z.B. 900,00',
  formLabelGrossWage: 'Bruttolohn in € (monatl.)',
  formPlaceholderGrossWage: 'z.B. 1600,00',
  formLabelExistingContract: 'Bestehender bAV-Vertrag',
  formLabelLanguagePreference: 'Sprachpräferenz',
  formLabelAddUserRole: 'Neue Rolle vergeben',
  formLabelWithdrawRole: 'Rolle entziehen',
  formLabelOldPassword: 'Altes Passwort',
  formLabelRepeatPasswordProfile: 'Wiederholen',
  formLabelNotificAdress: 'Adressänderungen',
  formLabelNotificContract: 'Vertragsanpassungen',
  formLabelNotificCompanyLeave: 'Mitarbeiteraustritt',
  formLabelNotificStatus: 'Statusänderungen',
  formLabelRemindMultiple: 'Mehrere Reminder versenden',
  formLabelDeleteMultiple: 'Mehrere Mitarbeiter löschen',
  formLabelEmployeePaginationLeft: 'Vorherige Mitarbeiter anzeigen',
  formLabelEmployeePaginationRight: 'Weitere Mitarbeiter anzeigen',
  formLabelResults: 'Ergebnisse',
  formLabelButtonResults: 'Ergebnisanzahl anpassen',
  formPlaceholderSearch: 'Suchen nach',
  formPlaceholderDocuments: 'Bsp. Musterdokument.pdf',
  formLabelSubmitSearch: 'Suche starten',
  formLabelEditDataIcon: 'Felder bearbeiten',
  formLabelCancelEdit: 'Abbrechen',
  formLabelSaveEdit: 'Speichern',
  formLabelStatusStandard: 'Standard',
  formLabelStatusParentalLeave: 'Mutterschutz / Elternzeit',
  formLabelStatusSickLeave: 'Krankheit',
  formLabelStatusResigned: 'Ausgetreten',
  formLabelShareDocument: 'Dokument teilen',
  formLabelDeleteDocuments: 'Dokumente löschen',
  formLabelDownloadDocuments: 'Dokumente herunterladen',
  formLabelDocsPaginationLeft: 'Vorherige Dokumente anzeigen',
  formLabelDocsPaginationRight: 'Weitere Dokumente anzeigen',
  formLabelCheckAllDocs: 'Alle Dokumente überprüfen',
  formLabelDeleteDocument: 'Datei löschen',
  formLabelDownloadDocument: 'Datei herunterladen',
  formLabelRemoveFile: 'Datei entfernen',
  formLabelEditCategory: 'Kategorie bearbeiten',
  formLabelPhoneOrMobile: 'Telefon- oder Mobilnummer',
  formLabelReferencePerson: 'Bezugsberechtigte Person',
  formPlaceholderReferencePerson: 'Ehepartner',
  formLabelEditReferencePerson: 'Bezugsberechtige Person bearbeiten',
  formLabelDeleteSearch: 'Eingabe löschen',
  formLabelSetContribution: 'Gewünschter Beitrag',
  formLabelNewContribution: 'Neuer Beitrag',
  formLabelExpand: 'Mehr anzeigen',
  formLabelCollapse: 'Weniger anzeigen',
  formLabelReminderDropdown: 'Erinnern an',
  formLabelReminderMessage: 'Nachricht',
  formLabelShareWith: 'Teilen mit',
  formLabelLanguageGerman: 'Deutsch',
  formLabelLanguageGermanFormal: 'Deutsch (förmlich)',
  formLabelLanguageGermanInformal: 'Deutsch (umgangssprachlich)',
  formLabelLanguageEnglish: 'Englisch',
  labelGoBack: 'Zurück',
  formPlaceholderSelect: 'Bitte auswählen',
  paginationLabelTill: 'von',
  buttonLogin: 'Log in',
  buttonConfirm: 'Bestätigen',
  buttonReset: 'Zurücksetzen',
  buttonPlusIconFAQ: 'Antwort anzeigen',
  buttonMinusIconFAQ: 'Antwort verbergen',
  buttonVideoCall: 'Videotermin',
  buttonSavePassword: 'Passwort speichern',
  buttonSaveData: 'Daten speichern',
  buttonSaveCookie: 'Cookie speichern',
  buttonSwitchRole: 'Zum Mitarbeiter Profil',
  buttonExcelImport: 'Excel-Import',
  buttonManualImport: 'Manueller Import',
  buttonStatusFilter: 'Nach Status filtern',
  buttonCompanyFilter: 'Nach Unternehmen filtern',
  buttonCategoryFilter: 'Nach Kategorie filtern',
  buttonEditEmployee: 'Mitarbeiter bearbeiten',
  buttonRemindEmployee: 'Reminder an Mitarbeiter versenden',
  buttonShareDocument: 'Dateien mit Mitarbeiter teilen',
  buttonDeleteEmployee: 'Mitarbeiter löschen',
  buttonSelectFile: 'Datei auswählen',
  buttonSelectCategory: 'Dokumentenkategorie festlegen',
  buttonEdittCategory: 'Dokumentenkategorie bearbeiten',
  buttonCancel: 'Abbrechen',
  buttonConclude: 'Abschließen',
  buttonAcceptOffer: 'bAV einrichten',
  buttonRequestOffer: 'Angebot anfordern',
  buttonShowContractDetails: 'Vertragsdetails zeigen',
  buttonPrivateChat: 'Privater Chat',
  buttonDeclineOffer: 'Verzicht einreichen',
  buttonUploadOldContract: 'Alten/bestehenden bAV-Vertrag hinterlegen',
  buttonPersonalProfile: 'Zum persönlichen Profil',
  buttonProductDataSheetDownload: 'Download Produktdatenblatt',
  buttonProductFactSheetDownload: 'Download Factsheet',
  buttonBack: 'Zurück',
  buttonContinue: 'Weiter',
  buttonSend: 'Absenden',
  buttonMoveCompanyPension: 'bAV-Vertrag umziehen',
  buttonImport: 'Importieren',
  buttonAcceptAndContinue: 'Zustimmen & weiter',
  buttonGoToLogin: 'Zur Login-Seite',
  buttonFinish: 'Schließen',
  buttonExampleDownload: 'Beispielrechnung',
  buttonCompanyInfoDownload: 'Firmeninformation herunterladen',
  buttonContinueToPortal: 'Weiter ins Portal',
  buttonAdd: 'Hinzufügen',
  buttonAddEmployee: 'Mitarbeiter hinzufügen',
  buttonStartChat: 'Chat starten',
  tableHeaderLastName: 'Nachname',
  tableHeaderFirstName: 'Vorname',
  tableHeaderCompany: 'Unternehmen',
  tableHeaderOrganization: 'Organisation',
  tableHeaderSalutation: 'Anrede',
  tableHeaderActions: 'Aktionen',
  tableHeaderStatus: 'Status',
  formLabelStatus: 'Status',
  tableHeaderInsurer: 'Versicherer',
  tableHeaderDocuments: 'Dokumente',
  tableHeaderCategory: 'Kategorie',
  tableHeaderSecurityScanStatus: 'Scan',
  tableHeaderCompanies: 'Unternehmen',
  tableHeaderTimeDate: 'Zeit/Datum',
  tableHeaderSharedWith: 'Geteilt mit',
  tooltipUserSystemId: 'Dies ist eine vom System vergebene persönliche Identifikationsnummer.',
  tooltipRightArrowDisabled: 'Es existieren keine weiteren Ergebnisse.',
  tooltipSaveEditButtonDisabled: 'Es existieren keine zu speichernden Änderungen.',
  tooltipStatusResignedIcon:
    'Wenn der Mitarbeiter nicht mehr in deinem Unternehmen tätig ist, kannst du ihn als „ausgetreten“ melden.',
  tooltipDocsPaginationRight: 'Es existieren keine weiteren Dokumente.',
  tooltipVideocallButton: 'Videotermin vereinbaren',
  tooltipChatButton: 'Mit Berater chatten',
  tooltipShareFileButton: 'Datei mit Mitarbeiter teilen',
  tooltipEditEmployeeButton: 'Mitarbeiter bearbeiten',
  copyright: `© ${new Date().getFullYear()} Degura GmbH. Alle Rechte vorbehalten.`,
  consultantDescriptionMale: 'Account Manager bei Degura',
  consultantDescriptionFemale: 'Account Managerin bei Degura',
  formTitleLogin: 'Login',
  exampleCalcsTaxLabel: 'Steuer',
  exampleCalcsSocialLabel: 'Sozialversicherung',
  exampleCalcsGrossWageLabel: 'Bruttogehalt',
  exampleCalcsNetWageLabel: 'Nettogehalt',
  exampleCalcsTotalContrLabel: 'Gesamtbeitrag',
  exampleCalcsMonthlyLabel: 'mtl.',
  exampleCalcsTaxSavingsLabel1: 'Steuer & Sozialversicherung',
  exampleCalcsTaxSavingsLabel2: 'Ersparnis',
  exampleCalcsTaxSavingsLabel: 'Steuer- & SV.-Ersparnis',
  exampleCalcsEmployerSubsidyLabel: 'Arbeitgeberzuschuss',
  exampleCalcsOwnContributionLabel: 'bAV-Eigenbeitrag',
  exampleCalcsWithBAVLabel: 'mit bAV',
  exampleCalcsWithoutBAVLabel: 'ohne bAV',
  exampleCalcsCapitalPaymentHeadline1: 'Prognostizierte Kapitalleistung',
  exampleCalcsCapitalPaymentHeadline2: 'in Abhängigkeit der Wertentwicklung',
  exampleCalcsGuaranteedPaymentLabel: 'Garantierte Zahlung',
  exampleCalcsPredictedPaymentLabel: 'Prognostizierte Zahlung',
  aboutBavGraphicsRetirementLabel1: 'Bisher',
  aboutBavGraphicsRetirementLabel2: 'Altersvorsorge',
  exampleCalcEmployersContributionLabel2: 'Arbeitgeberbeitrag',
  aboutBavSocialSavingsLabel: 'Sozialversicherung',
  faqQuestion1: 'Lohnt sich die betriebliche Altersvorsorge für jeden Arbeitnehmenden?',
  faqQuestion2: 'Wie hoch darf mein monatlicher Beitrag sein?',
  faqQuestion3: 'Welcher monatliche Beitrag ist empfehlenswert?',
  faqQuestion4: 'Kann ich meine Beiträge anpassen, wenn sich meine finanzielle Situation verändert?',
  faqQuestion5:
    'Welche Optionen habe ich, sollte ich in Elternzeit gehen, mich selbstständig machen oder arbeitslos werden?',
  faqQuestion6: 'Kann ich meinen Vertrag auch im Ausland privat weiter fortführen?',
  faqQuestion7: 'Was passiert mit meinem Vertrag, wenn sich mein Arbeitgeber ändert?',
  faqQuestion8: 'Ist meine betriebliche Altersvorsorge sicher, wenn der Arbeitgeber Insolvenz anmeldet?',
  faqQuestion9: 'Ab wann habe ich Anspruch auf Auszahlung meiner betriebliche Altersvorsorge?',
  faqQuestion10: 'Wie bekomme ich meine betriebliche Altersvorsorge ausgezahlt?',
  faqQuestion11: 'Was passiert im Fall meines Todes?',
  faqQuestion12: 'Sinkt mit der betrieblichen Altersvorsorge meine gesetzliche Rente?',
  faqQuestion13: 'Welche Leistungen sind möglich?',
  faqQuestion14: 'Welche Auswirkung hat die betriebliche Altersvorsorge auf meine Steuererklärung?',
  faqQuestion15: 'Muss ich auf meine lebenslange Rente Abgaben zahlen?',
  faqQuestion16: 'Muss ich auf meine Kapitalauszahlung Abgaben zahlen?',
  faqQuestion17: 'Welche Kosten entstehen bei Abschluss einer betriebliche Altersvorsorge?',
  notSpecified: 'Keine Angabe',
  mandatoryFieldNote: '*Pflichtfeld',
  companyPensionStatusOnboarded: 'Angemeldet',
  companyPensionStatusContacted: 'Angeschrieben',
  companyPensionStatusOfferOptedOut: 'Verzicht',
  companyPensionStatusOfferRequested: 'In Beratung',
  companyPensionStatusOfferAccepted: 'Abgeschlossen',
  companyPensionStatusSigned: 'Vertrag',
  companyPensionStatusOldContract: 'Bestandsvertrag',
  companyPensionStatusApplication: 'Beantragt',
  companyPensionStatusSuspended: 'Abgemeldet',
  roleTab1: 'HR-Admins',
  roleTab2: 'HR-Verwalter',
  tabAll: 'Alle',
  tabDeleted: 'Gelöscht',
  exampleCalcCaseLabel1: 'Fall 1:',
  exampleCalcCaseLabel2: 'Fall 2:',
  exampleCalcCaseLabel3: 'Fall 3:',
  exampleCalcGrossWageLabel: 'Bruttolohn:',
  exampleCalcContributionLabel: 'Netto bAV-Beitrag:',
  exampleCalcDateOfBirthLabel: 'Geburtsdatum:',
  exampleCalcChurchTaxLabel: 'Kirchensteuer:',
  exampleCalcChildTaxAllowanceLabel: 'Kinderfreibetrag:',
  exampleCalcEmployersSubsidyLabel: 'Arbeitgeberzuschuss:',
  exampleCalcEmployersContributionLabel: 'Arbeitgeberbeitrag:',
  exampleCalcTaxClassLabel: 'Steuerklasse:',
  employeeName: '{FIRSTNAME_EMPLOYEE} {LASTNAME_EMPLOYEE}',
  uploadInfo2: 'oder',
  titleMaxUploadAmount: 'Zu viele Dateien ausgewählt',
  progressOnboarding: 'Einführung',
  progressExamples: 'Rechenbeispiele',
  progressCTA: 'Entscheidung',
  progressAcceptData: 'Persönliche Daten',
  progressAcceptContact: 'Kontaktdaten',
  progressAcceptTax: 'Steuerliche Daten',
  progressAcceptBAV: 'Angaben zur bAV',
  progressOnboarded: 'Angemeldet',
  progressOfferRequested: 'Angebot angefragt',
  progressOfferAccepted: 'bAV abgeschlossen',
  progressOfferDeclined: 'Verzicht abgegeben',
  progressOldContract: 'Altvertrag hinterlegt',
  paragraphFunctionality2:
    'Aus dem Nettogehalt werden dann alle laufenden Kosten für die Familie, für das Haus, die Wohnung oder das Auto bezahlt. Da die gesetzliche Rentenversicherung im Alter nicht mehr ausreicht, nutzen viele Menschen ihr Nettogehalt, um privat für ihren Ruhestand vorzusorgen. Die betriebliche Altersvorsorge funktioniert anders – und rückt deshalb immer mehr in den Mittelpunkt.',
  descriptionCTADeclineOffer:
    'Ich habe für meinen Ruhestand bereits anderweitig vorgesorgt und möchte die Vorteile einer bAV nicht in Anspruch nehmen.',
  fieldLabelEmployersContribution: 'Arbeitgeberbeitrag',
  fieldLabelTotalContribution: 'Gesamtbeitrag',
  calculatorBoxNote:
    '*Bei den berechneten Daten handelt es sich um Annäherungen, die vom tatsächlichen Ergebnis abweichen können.',
  notFoundDocuments: 'Es existieren keine Dokumente mit den angegebenen Suchkriterien.',
  notFoundEmployees: 'Es existieren keine Mitarbeiter mit den angegebenen Suchkriterien.',
  searchFilterLabel: 'Filter',
  searchEmployeesLabel: 'Mitarbeiter',
  searchFilterAddedToday: 'Heute hinzugefügt',
  searchFilterAddedLast7Days: 'in den letzten 7 Tage hinzugefügt',
  searchFilterParentalLeave: 'In Mutterschutz/Elternzeit',
  searchFilterDecommissioned: 'Stillgelegt',
  searchFilterResigned: 'Ausgetreten',
  introHomepageWelcome: 'Willkommen in Ihrem bAV-Portal',
  homepageSectionOneText: 'Ich möchte Informationen zur betrieblichen Altersvorsorge meines Unternehmens erhalten.',
  homepageSectionOneButton: 'Mehr erfahren',
  homepageSectionTwoText:
    'Ich möchte einen Beratungstermin vereinbaren, um Fragen zur betrieblichen Altersvorsorge meines Unternehmens zu besprechen.',
  homepageSectionTwoButton: 'Beratung vereinbaren',
  homepageSectionThreeText:
    'Ich möchte jetzt mit jemandem über Fragen sprechen, die ich zur betrieblichen Altersvorsorge meines Unternehmens habe.',
  homepageSectionThreeButton: 'Privater Chat',
  homepageSectionFourText:
    'Ich habe bereits andere Vorkehrungen für meinen Ruhestand getroffen und möchte das Angebot der betrieblichen Altersvorsorge meines Unternehmens nicht nutzen.',
  homepageSectionFourButton: 'Verzicht einreichen',
  introEmployeeWelcome: 'Willkommen im bAV-Portal von {COMPANY}',
  headlineChangePassword: 'Neues Passwort festlegen',
  headlinePersonalData: 'Persönliche Daten',
  headlineTaxData: 'Steuerliche Daten',
  headlineRoleManagement: 'Rollen-Zuweisung',
  headlineCompanies: 'Unternehmen',
  headlineRoles: 'Rollen',
  headlineSecurity: 'Sicherheitseinstellungen',
  headlineNotifications: 'E-Mail Benachrichtigungen',
  headlineEmployeeOverview: 'Mitarbeiterübersicht',
  headlineEmployeeEdit: 'Profil von {NAME}',
  headlineEmployeeStatus: 'Mitarbeiterstatus',
  headlineCompanyPensionStatus: 'bAV-Status',
  headlineSharedDocuments: 'Geteilte Dokumente',
  headlineSaveEditing: 'Änderungen speichern',
  headlineUnsavedChanges: 'Nicht gespeicherte Änderungen',
  headlineCancelEdit: 'Bearbeitung abbrechen',
  headlineSaveEmployeeStatus: '{NAME} wirklich aus der Liste entfernen?',
  headlineDeleteEmployeeOverlay: '{NAME} wirklich löschen?',
  headlineFinishUpload: 'Datei-Upload abschließen',
  headlineIntroVideo: 'Was ist eine bAV?',
  headlineFunctionality: 'Welche finanziellen Vorteile habe ich durch eine bAV?',
  subHeadlineFunctionality1: 'Altbekanntes Szenario ohne den Abschluss einer bAV:',
  subHeadlineFunctionality2: 'Altbekanntes Szenario mit Rentenversicherung:',
  subHeadlineFunctionality3: 'Aktuelles Szenario mit bAV:',
  headlineExampleCalculations: 'Wie lassen sich die finanziellen Vorteile einer bAV berechnen?',
  headlineExamplesSubsidies: 'Anteil der Zuschüsse am Gesamtbeitrag',
  headlineCapitalPayment: 'Wertzuwachs während der Einzahlungsphase',
  headlineExampleCalculationsDownload: 'Die Beispielrechnung als Download',
  headlineCompanyInfoDownload: 'Informationen zur bAV bei {COMPANY}',
  headlineInsuranceProductOverview: 'Übersicht deiner Versicherungsprodukte',
  headlineDecline: 'Verzicht auf eine bAV',
  headlineCaseOfDeath: 'Welche Person soll im Todesfall bezugsberechtigt sein?',
  headlineMonthlyContribution: 'Monatlicher Beitrag',
  headlineEmployeeDocuments: 'Persönliche Dokumente',
  headlineEmployerDocuments: 'Alle Dokumente auf einen Blick',
  headlinePrivacyPolicy: 'Datenschutzinformationen für Kunden und Interessenten',
  headlineNewEvents: 'Neue Ereignisse',
  headlineOldEvents: 'Vergangene Ereignisse',
  headlineInsurer: 'Versicherer',
  headlineDeleteDocuments: 'Dokumente löschen',
  headlineImportEmployee: 'Mitarbeiter importieren',
  headlineSelectEmployee: 'Mitarbeiter auswählen',
  headlineSelectCompany: 'Unternehmen auswählen',
  headlineDeleteSelectedDocuments: 'Dokumente löschen',
  headlineArchiveDeleteSelectedDocuments: 'Dokumente unwiderruflich löschen',
  headlineProductDataSheet: 'Produktdatenblatt',
  headlineContractProposals: 'Vertragsvorschläge',
  headlineFundOverview: 'Fondübersicht',
  headlineConsumerInformation: 'Verbraucherinformationen',
  headlineInsuranceConditions: 'Versicherungsbedingungen',
  headlineRegistrationSuccess: 'Registrierung erfolgreich!',
  headlineDeleteSelectedEmployees: 'Mitarbeiter entfernen',
  headlineDeleteEmployee: 'Mitarbeiter löschen',
  headlineChangeBavStatus: 'Änderung des bAV-Status',
  headlineCookieConsentForFeature: 'Nutzung von Cookies',
  headlineCreateCompany: 'Neue Firma anlegen',
  newOrg: 'Neu anlegen',
  existingOrg: 'Verwende existierende',
  messageEmployeeDeletedTitle: 'Der Mitarbeiterdatensatz wurde gelöscht',
  messageEmployeeDeleted: 'Der Mitarbeiterdatensatz wurde erfolgreich gelöscht.',
  messageEmployeeDataChangedTitle: 'Der Mitarbeiterdatensatz wurde aktualisiert!',
  messageEmployeeStatusChanged: 'Der Status des Mitarbeiters wurde aktualisiert.',
  messageEmployeeDataChanged: 'Ihre Daten wurden erfolgreich aktualisiert',
  messageTotalContributionWithoutEmployerContribution:
    'Wie du siehst, investiert {NAME} {PERSONAL_CONTRIBUTION} aus dem Nettolohn in die bAV. Daraus ergibt sich – durch den geringeren Bruttolohn – eine Steuer- und Sozialversicherungsersparnis von insgesamt {SOCIAL_CONTRIBUTION} monatlich. Diese fließt direkt in die betriebliche Altersvorsorge, ebenso der Arbeitgeberzuschuss in Höhe von {EMPLOYER_SUBSIDY_CURRENCY}. Anstatt nur {PERSONAL_CONTRIBUTION}, werden für {NAME} letztendlich {TOTAL_CONTRIBUTION} monatlich zurückgelegt.',
  messageTotalContributionWithEmployerContribution:
    'Wie du siehst, investiert {NAME} {PERSONAL_CONTRIBUTION} aus dem Nettolohn in die bAV. Daraus ergibt sich – durch den geringeren Bruttolohn – eine Steuer- und Sozialversicherungsersparnis von insgesamt {SOCIAL_CONTRIBUTION} monatlich. Diese fließt direkt in die betriebliche Altersvorsorge, ebenso der Arbeitgeberzuschuss in Höhe von {EMPLOYER_SUBSIDY_CURRENCY} und der Arbeitgeberbeitrag von {EMPLOYER_CONTRIBUTION_LABEL}. Anstatt nur {PERSONAL_CONTRIBUTION}, werden für {NAME} letztendlich {TOTAL_CONTRIBUTION} monatlich zurückgelegt.',
  messageExampleSubsidies:
    'Wir gehen davon aus, dass {NAME} für {CONTRACT_DURATION} Jahre monatlich {PERSONAL_CONTRIBUTION} in die bAV einzahlt. Der Gesamtbetrag der Eigenbeteiligung nach {CONTRACT_DURATION} Jahren beläuft sich dann auf {TOTAL_PAID_OWN}.\n \nDie garantierte Kapitalleistung beträgt nach dieser Zeit {GUARANTEED_CAPITAL_PAYMENT}.\n \nDas bedeutet, dass {TOTAL_PAID_OTHER}, die {NAME} im Alter ausgezahlt werden, ausschließlich aus Zuschüssen bestehen.',
  messageExampleValueDevelopment:
    'In diesem Beispiel nehmen wir zudem an, dass {NAME} über denselben Zeitraum ({CONTRACT_DURATION} Jahre) eine jährliche Rendite von {ANNUAL_RETURN} mit dem bAV-Vertrag erwirtschaften kann.\n\nDamit erhöht sich die Kapitalleistung auf insgesamt {CAPITAL_PAYMENT_PROJECTED}.',
  messageImpersonation: 'Du befindest dich in der Rolle von',
  messageContractualDocuments:
    'In diesem Bereich findest du alle für dein Unternehmen relevanten Dokumente, wie Versorgungsordnung und Gruppenverträge deiner Mitarbeiter.',
  messageStopImpersonation: 'Klicke hier, um den Rollentausch zu beenden.',
  mobileFormLabelBurgerMenuIcon: 'Hauptnavigation',
  logoDescriptionCooperation: 'In Kooperation mit',
  mobileTooltipUploadedFileInfoIcon: '{FILE_UPLOAD_TIMESTAMP}<br/>{FILE_UPLOAD_DATE}<br/>{FILESIZE}',
  successDocumentsDeletedHeadline: 'Dokumente gelöscht!',
  successDocumentsDeleted: 'Die ausgewählten Dokumente wurde gelöscht.',
  successChangeFieldHeadline: 'Das Feld wurde erfolgreich geändert.',
  successChangeStatus: 'Der Status wurde erfolgreich geändert.',
  successChangePassword: 'Das Passwort wurde erfolgreich geändert.',
  successExcelImportHeadline:
    'Es wurden {count} neue Mitarbeiter aus der Liste importiert. Sie sind nun in der folgenden Tabelle enthalten.',
  successHeadline: 'Erfolgreich!',
  successGrantChangeMessage: 'Die Freigabeeinstellungen für das Dokument wurden erfolgreich aktualisiert.',
  errorExcelImportInLines: 'in den Zeilen:',
  errorExcelImportCompanyUnknown: 'Unbekanntes Unternehmen',
  errorExcelImportInvalidEmail: 'Ungültige E-Mail-Adresse',
  errorExcelImportInvalidSalutation: 'Ungültige Anrede',
  errorExcelImportDuplicate: 'Die Datensätze sind bereits im System vorhanden.',
  errorExcelImportWrongFormat: 'Die hochgeladene Datei ist keine Excel-Datei.',
  errorExcelImportWrongFileStructure: 'Die hochgeladene Datei entspricht leider nicht der Vorlage.',
  errorExcelImportAccessDenied: 'Keine Berechtigung zur Verwaltung von Mitarbeitern des Unternehmens',
  errorExcelImportInvalidStreetAddress: 'Ungültige Adresse',
  errorExcelImportInvalidPostalCode: 'Ungültige Postleitzahl',
  errorExcelImportInvalidLanguagePreference: 'Ungültige Sprachpräferenz',
  errorExcelImportInvalidCountry: 'Unbekanntes Land',
  ErrorEmployeeCreateDuplicate: 'Dieser Mitarbeiter ist im System bereits angelegt.',
  errorInvalidPhoneMax: 'Die Telefonnummer darf nicht mehr als 20 Ziffern beinhalten.',
  errorPercentageInvalid: 'Der Wert muss zwischen 0 und 100 liegen',
  errorChangeFieldHeadline: 'Hoppla!',

  BENEFICIARY_LEGAL_SUCCESSION: 'Gesetzliche Erbfolge',
  BENEFICIARY_PARTNER: 'Partner/in im gemeinsamen Haushalt',
  BENEFICIARY_DEATH_GRANT: 'Begünstigte/r des Sterbegeldes',
  BENEFICIARY_NONE: 'Keine Auswahl',

  DOC_CONVERSION_AGREEMENT: 'Vereinbarung (EUV)',
  DOC_CONSULTATION: 'Beratungsdokumentation',
  DOC_CONSUMER_INFO: 'Verbraucherinformation',
  DOC_CUSTOMER_INFO: 'Verbraucherinformation',
  DOC_REQUEST: 'Antrag',
  DOC_POLICY: 'Police',
  DOC_TERMS: 'Bedingungen (AVB)',
  DOC_FORM: 'Formular',
  DOC_BALANCE_REPORT: 'Standmitteilung',
  DOC_INCIDENT: 'Vorgang',
  DOC_PRODUCT_INFO: 'Information',
  DOC_OTHER: 'Sonstiges',
  DOC_OFFER: 'Angebot',
  DOC_CHECKLIST: 'Checkliste',
  DOC_CLIENT_INFO: 'Kunden-Erstinformation',
  DOC_PROCESSING_AGREEMENT: 'Auftragsdatenverarbeitungsvertrag',
  DOC_MANDATE: 'Maklermandat',
  DOC_LIST_REPORT: 'Listenantrag',
  DOC_COLLECTIVE_AGREEMENT: 'Kollektivverträge',
  DOC_PENSION_SCHEME: 'Versorgungsordnung',
  DOC_REPORTING: 'Berichte',
  DOC_WAIVER: 'Verzichtserklärung',
  DOC_SECURITY_SCAN_PENDING: 'Ausstehend',
  DOC_SECURITY_SCAN_CLEAN: 'OK',
  DOC_SECURITY_SCAN_INFECTED: 'Infiziert',
  placeholderSearchEmployee: 'z.B. Max Müller',
  uploadInProgress: 'Dokument wird hochgeladen',
  uploadFailed: 'Upload fehlgeschlagen',
  completeProcess: 'Abschließen',
  errorPasswordTooShort:
    'Das Passwort sollte mindestens 8 Zeichen, davon 1 Großbuchstaben, 1 Kleinbuchstaben, 1 Zahl und 1 Sonderzeichen enthalten.',
  // error password hint box text
  errorPasswordTitle: 'Anforderungen an das Passwort',
  errorPasswordCharacters: '8 Zeichen',
  errorPasswordUpperCase: '1 Großbuchstaben',
  errorPasswordLowerCase: '1 Kleinbuchstaben',
  errorPasswordNumber: '1 Zahl',
  errorPasswordSpecialCharacter: '1 Sonderzeichen',
  // ... //
  errorPasswordRequired: 'Bitte Passwort eingeben',
  errorPasswordRepeatRequired: 'Bitte Passwort wiederholen',
  errorPasswordMatch: 'Die Passwörter stimmen nicht überein',
  error404Title: 'Hoppla, hier fehlt was!',
  errorGenericTitle: 'Fehler',
  errorGenericMessage: 'Leider gab es einen Fehler in der Verarbeitung. Unser Support hilft gerne weiter.',
  errorSessionExpiredTitle: 'Die Sitzung ist abgelaufen.',
  errorNotAuthorizedTitle: 'Kein Zugriff',
  errorNotAuthorizedMessage: 'Leider fehlen die erforderlichen Berechtigungen für diese Transaktion.',
  errorTooYoung: 'Sie müssen mindestens 18 Jahre alt sein.',
  uploadCancelled: 'Upload abgebrochen',
  messageEmployeeImportedTitle: 'Mitarbeiter angelegt!',
  messageEmployeeImported:
    'Der Mitarbeiter wurde im System angelegt und wird jetzt in der Mitarbeiterliste aufgeführt.',
  errorEmployeeDuplicateEmail: 'Es existiert bereits ein Mitarbeiter mit dieser E-Mail-Adresse.',
  messageUnsavedChanges:
    'Du bist im Begriff, die Seite zu verlassen, ohne deine vorgenommenen Änderungen zu speichern. Wenn du fortfährst, gehen folgende Änderungen verloren:',
  buttonProceed: 'Fortfahren',
  buttonEditData: 'Daten bearbeiten',
  buttonCancelChanges: 'Änderungen verwerfen',
  chartLabelContributionOwn: 'Eigenbeteiligung',
  chartLabelContributionOther: 'Zuschüsse',
  successExistingContractPostedHeadline: 'Vertrag erfolgreich hinterlegt!',
  toggleLableShareWithHR: 'Personalabteilung',
  headlineShareWithHR: 'Dokumente für die Personalabteilung freigeben',
  errorEmployeeInvalidSalutation: 'Die Anrede ist ungültig.',
  successDocumentCategoryChangeMessage: 'Die Kategorie für das Dokument wurde angepasst.',
  titleSidebarButtonExpand: 'Mehr anzeigen',
  titleSidebarButtonCollapse: 'Weniger anzeigen',
  auth2faRemove: 'Zwei-Faktor-Authentifizierung entfernen',
  aut2faSetupDoNotShow: 'Nicht mehr anzeigen',
  aut2faSetupHeading: 'Zwei-Faktor-Authentifizierung',
  aut2faSetupIntro:
    'Um dein Konto zu schützen, richte bitte deinen Zwei-Schritte-Login ein. Nach jeder Anmeldung erhältst du zukünftig einen Code auf dein Mobiltelefon.',
  auth2faSetupInstructions1: 'Bitte gebe unten den Code ein, den du per SMS auf dein Mobiltelefon erhalten hast',
  auth2faSetupInstructions2: 'Du hast noch',
  auth2faSetupInstructions3: 'Sekunden Zeit, um den Code einzugeben.',
  auth2faLabelCode: 'Bestätigungscode',
  auth2faCodeRequired: 'Bestätigungscode',
  auth2faSendCode: 'Code nochmal senden',
  auth2faSetupExpired1: 'Deine Zeit zum Eingeben des SMS-Codes ist abgelaufen. Bitte überprüfe, ob deine Mobilnummer',
  auth2faSetupExpired2: 'korrekt ist und klicke auf “Code nochmal senden”, um einen neuen Code anzufordern.',
  auth2faSetupSuccess: 'Herzlichen Glückwünsch! Du hast deinen Zwei-Schritte-Login erfolgreich eingerichtet.',
  auth2faSetupPlaceholder: 'Mobilnummer',
  auth2faConfirmCodePlaceholder: 'Code',
  auth2faLoginIntro1: 'Bitte gebe unten den Code ein, den du per SMS auf dein Mobiltelefon ',
  auth2faLoginIntro2: 'erhalten hast. Du hast noch',
  auth2faLoginIntro3: 'Sekunden Zeit, um den Code einzugeben.',
  auth2faLoginExpired1: 'Deine Zeit zum Eingeben des SMS-Codes ist abgelaufen. Bitte überprüfe, ob deine Mobilnummer',
  auth2faLoginExpired2: 'korrekt ist und klicke auf “Code nochmal senden”, um einen neuen Code anzufordern.',
  auth2faSettingsHeading: 'Zwei-Faktoren-Authentifizierung - Einstellungen',
  auth2faOldNumber: 'Alte Mobilnummer',
  auth2faNewNumber: 'Neue Mobilnummer',
  auth2faCongratulations: 'Glückwunsch!',
  auth2faCongratulationsMsg: '2FA wurde erfolgreich eingerichtet.',
  auth2faSettingsMenuButton: '2FA-Einstellungen',
  auth2faBackToLogin: 'Zurück zum Login',
  auth2faBackToNumber: 'Zurück zur Nummerneingabe',
  auth2faToPortal: 'Zum Portal',
  auth2faLater: 'Später einrichten',
  linkLater: 'Später',
  contractChangeRequest: 'Antrag auf Vertragsänderung',
  contractChangeRequestIntroHr: 'Welche Änderung sollen an dem aktuellen bAV-Vertrag vorgenommen werden?',
  contractChangeRequestIntro: 'Welche Änderung möchten Sie am aktuellen bAV-Vertrag vornehmen?',
  contractChangeRequestLead: 'Bitte die folgenden Felder ausfüllen, damit wir das Anliegen bearbeiten können.',
  contractChangeRequestSend: 'Änderung senden',
  contractChangeRequestConfirmHeadline: 'Wir haben die Anfrage erhalten!',
  contractChangeRequestConfirmMsg:
    'Wir kümmern uns umgehend um Ihr Anliegen. Sie haben jederzeit die Möglichkeit den Status der Anfrage auf Ihrer bAV-Seite einzusehen.',
  contractChangeRequestOkay: 'Alles klar',
  changeRequest: 'Vertragsänderung',
  changeRequestSelectPlaceholder: 'Bitte auswählen…',
  requestsTitle: 'Anfragen',
  requestsDescription:
    'Nachfolgend finden Sie alle Ihre Änderungswünsche. Wenn Sie Fragen, Kommentare usw. zu Ihren Wünschen haben, kontaktieren Sie uns bitte über den Chat-Button unten rechts auf der Seite.',
  requestFieldType: 'Anfragetyp',
  requestFieldReporter: 'Reporter',
  requestFieldSelect: 'Bitte auswählen...',
  requestFieldInitial: 'Ursprünglicher Wert',
  requestFieldCurrent: 'Aktueller Wert',
  requestFieldNew: 'Neuer Wert',
  requestsTableColumnType: 'Typ',
  requestsTableColumnTime: 'Datum',
  requestsTableColumnStatus: 'Status',
  submit: 'Übermitteln',
  mobileNumberAlreadyInUse: 'Die eingegebene Mobilnummer wird bereits verwendet.',
  bAVOptionsQuestion: 'Was möchtest du als Nächstes tun?',
  bavOption1: 'bAV-Informationen erhalten',
  bavOption2: 'Persönliche Beratung buchen',
  bavOption3: 'Unverbindliches Angebot anfordern',
  bavOption3Onboarded: 'Meine Vertragsdetails anzeigen',
  bavOption4: 'Meine Vertragsdetails aufrufen',
  waiverText: 'Sie möchten keine bAV abschließen?',
  waiverLink1: 'Aktive bAV hinterlegen',
  waiverLink2: 'Verzicht einreichen',
  myProfile: 'Mein Profil',
  bookNow: 'Jetzt buchen',
  moreInformation: 'Informationen erhalten',
  changePassword: 'Passwort ändern',
  changePassWordText: 'Hier hast du die Möglichkeit dein aktuelles Passwort zu ändern.',
  menuBavInfo: 'bAV-Info',
  menuHome: 'Home',
  menuMyBav: 'Meine bAV',
  menuFaq: ' FAQ',
  introFAQ: 'Ihre Fragen, unsere Antworten',
  introSubFAQ: 'In diesem Bereich finden Sie zusammengefasst die wichtigsten Fragen und Antworten zur bAV.',
  subheadlineFAQ: 'Allgemein',
  subHeadingFAQ: 'Meistgestellte Fragen',
  linkHeaderOwnProfile: 'Persönliches Profil',
  loginWelcome: 'Herzlichen Willkommen in deinem bAV-Portal',
  messageeIntroVideo:
    'Im folgenden Video erfahren Sie mehr über die Leistungen einer bAV und wie Sie diese für sich nutzen können.',
  homeTitle: '⁣Degura bAV-Portal - Home',
  faqTitle: '⁣Degura bAV-Portal - FAQ',
  myBavTitle: '⁣Degura bAV-Portal - Meine bAV',
  bavInfoTitle: '⁣Degura bAV-Portal - bAV-Info',
  profileTitle: '⁣Degura bAV-Portal - Persönliches Profil',
  bavInfoHeading1: 'Alles, was du wissen solltest',
  bavInfoHeading2: 'Welche finanziellen Vorteile entstehen für mich durch eine bAV?',
  bavInfoIntroText:
    'Verschaffen Sie sich in drei Schritten einen einfachen Überblick über die Funktionsweise einer bAV.',
  bavTab1Text: 'Allgemeines zur bAV',
  bavTab2Text: 'Rechenbeispiele',
  bavTab3Text: 'Übersicht Versicherungspartner',
  requestOfferTileHeading: 'Du möchtest ein unverbindliches Angebot erhalten?',
  requestOfferTileText:
    'Wie hoch Ihre betriebliche Altersvorsorge ausfallen wird, hängt davon ab, wie lange und wie viel Sie einzahlen. Wir zeigen Ihnen gerne auf, wie Sie Ihre gesetzliche Rente aufbessern können.',
  requestOfferTileButton: 'Angebot anfordern',
  advantagesTileHeading: 'Sie möchten sich gerne die Vorteile einer bAV im Detail aufzeigen lassen?',
  advantagesTileText:
    'Es gibt viele Möglichkeiten, Ihre finanzielle Basis für später aufzubessern. Wir erklären die bAV und Ihre Optionen.',
  advantagesTileButton: 'Beratung vereinbaren',
  personalProfileInfoText:
    'Das Ändern von Daten auf dieser Seite zieht keine Vertragsänderung nach sich. Solltest du deinen Vertrag anpassen wollen, bitten wir dich dies unter <a><strong>Meine bAV</strong></a> zu tun.',
  personalProfileHeadingPersonal: 'Persönliche Angaben',
  personalProfileHeadingFinancial: ' Finanzielle Angaben',
  bavStatus: 'Status bAV',
  accountSetup: 'Konto einrichten',
  salaryDetails: 'Lohndetails angeben',
  bavInformation: 'bAV-Angaben',
  bavSetup: 'bAV-einrichten',
  summaryInformation: 'Korrektheit sicherstellen',
  checkData: 'Prüfen der Angaben',
  enterInformation: 'Fordere jetzt ganz einfach dein persönliches unverbindliches Angebot an.',
  myContractData: 'Meine Vertragsdaten',
  myChangeRequests: 'Meine Vertragsanpassungen',
  largeTilesIntroDeclined: 'Sie interessieren sich doch für eine bAV?',
  largeTilesIntroOld: 'Sie sind mit Ihrer aktuellen bAV nicht zufrieden?',
  enterInformation2:
    'Um ein unverbindliches Angebot für Sie erstellen zu können, benötigen wir noch einige steuerliche Angaben. Natürlich gehen wir sehr vertraulich mit Ihren Daten um und halten die aktuellen Datenschutzgesetze ein.',
  uploadBavMessage: 'Bitte laden Sie hier die bestehende bAV hoch',
  uploadBavSelectFile: 'Datei auswählen',
  uploadBavConfirm:
    'Ihre aktuelle bAV wurde erfolgreich hochgeladen und in Ihren Dokumenten unter „Meine bAV“abgelegt.',
  submitWaiverMessage: 'Möchten Sie wirklich auf deine bAV-Leistung verzichten?',
  submitWaiverConfirm:
    'Die Verzichtserklärung wurde erfolgreich an uns übermittelt. <br>Es gibt jedoch jederzeit die Möglichkeit, Ihre Verzichtserklärung zu widerrufen und zu einem späteren Zeitpunkt eine betriebliche Altersvorsorge abzuschließen.',
  adminEmployeeHeadingOverview: 'Übersicht Mitarbeiter',
  adminEmployeeHeadingLead:
    'Fügen Sie neue Mitarbeiter in einer Excel-Datei (eine Vorlage können Sie <a href="https://assets.degura.de/upload-template/Degura_Mitarbeiterliste_Vorlage.xlsx">hier</a> herunterladen) oder manuell in der Übersicht hinzu. <br>Sie können nachträglich jederzeit neue Mitarbeiter hinzufügen und alte aus der Liste entfernen.',
  adminEmployeeSearchLabel: 'Mitarbeiter suchen',
  adminCompanyFilterLabel: 'Nach Unternehmen filtern ',
  adminOrganizationFilterLabel: 'Nach Organization filtern ',
  adminCompanyFilterStatusLabel: 'Nach Status filtern',
  adminSuccessDeactivateCompany: 'Das Unternehmen wurde erfolgreich gelöscht',
  adminCompanyDocumentHeading: 'Übersicht Firmendokumente',
  adminCompanyDocumentsLead:
    'In diesem Bereich finden Sie alle für dein Unternehmen relevanten Dokumente, wie Versorgungsordnung und Gruppenverträge Ihrer Mitarbeiter.',
  adminEmployeeUpload: 'Mitarbeiterliste hochladen',
  adminEmployeeAdd: 'Mitarbeiter anlegen',
  adminEmployeeTablePerPage: 'Mitarbeiter pro Seite:',
  personalProfileInfoTextHRAdmin:
    'Das Ändern von Daten auf dieser Seite zieht keine Vertragsänderungen nach sich. Solltest Sie Vertragsdetails anpassen wollen, bitten wir Sie dies unter <strong><a>Vertragsänderung</a></strong> zu tun.',
  profileDeleteUser: 'Sie sind im Begriff <b>{USER}</b> zu entfernen. Sind Sie sich sicher?',
  cookieConsentMessage:
    'Wir verwenden eigene Cookies und solche von Drittanbietern, um unser Leistungsangebot zu optimieren und verschiedene Komfortfunktionen (wie Chat und Terminvereinbarung) für Sie anzubieten. Mit Klick auf “Akzeptieren” stimmen Sie deren Verwendung und einer optimierten Nutzung unserer Webseite zu.',
  cookieConsentHeadline: '',
  cookieConsentDescription:
    'Wir nutzen Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und deine Online-Erfahrungen zu verbessern. Sie können jederzeit die Kategorien an- und ausstellen.',
  cookieConsentAccept: 'Akzeptieren',
  cookieConsentManage: 'Cookie-Einstellungen',
  cookieConsentAnalytics: 'Analytische Cookies',
  cookieConsentAnalyticsDesc:
    'Ermöglicht es uns, unsere Website durch anonymes Aufzeichnen des Benutzerverhaltens zu optimieren. Weitere Informationen zur Art der von uns durchgeführten Nachverfolgung finden Sie in unseren Datenschutzbestimmungen.',
  cookieConsentAcceptAll: 'Alle Cookies akzeptieren',
  cookieConsentUse: 'Unsere Nutzung der Cookies',
  cookieConsentManagement: 'Cookie-Verwaltung',
  cookieConsentStrict: 'Unbedingt erforderliche Cookies',
  cookieConsentNecessary:
    'Erforderlich, um Ihre Einstellungen zu speichern und die Kernfunktionen unserer Plattform zu steuern, z. B. Anmeldung, Spracheinstellungen, sowie Chat-Funktionen um mit unseren Kundenberatern in Kontakt zu treten.',
  uploadDocumentIntro1: 'Datei(en) in das Feld ziehen oder Dateien hochladen',
  uploadDocumentIntro2: ' (maximal 5 Dateien).',
  uploadDocumentFileTypes: 'PDF, PNG, JPEG, XLSX und XLS werden bis zu einer Größe von 10 MB unterstützt .',
  uploadDocumentUploading: 'Dokumente werden hochgeladen',
  uploadDocumentUploadingCount: 'Dokument(en) hochgeladen',
  uploadDocumentCancel: 'Hochladen abbrechen',
  uploadDocumentCanceledMsg:
    'Der Dokumenten-Upload wurde abgebrochen! In der Tabelle kann geprüft werden, welche Dokumente bis zum Abbruch bereits hochgeladen wurden. Falls gewünscht, können diese dort händisch gelöscht werden.',
  uploadDocumentFailedMsg: 'Hochladen von Dokument(en) fehlgeschlagen!',
  uploadDocumentSuccessMsg: 'Hochladen von Dokument(en) war erfolgreich!',
  uploadDocumentFailedAlert: ' konnte aufgrund ',
  uploadDocumentFailedAlert2: ' nicht erfolgreich hochgeladen werden!',

  uploadDocumentTryAgain: 'Erneut versuchen',
  uploadDocumentSkip: 'Überspringen',
  uploadDocumentSupport1:
    'Bitte versuchen Sie es erneut. Sollte das Problem weiterhin bestehen, kontaktieren Sie bitte den ',
  uploadDocumentSupport2: 'Support',
  uploadDocumentNow: 'Gerade eben',

  // Error codes
  CAPITAL_FORMATION_CONTRIBUTION_INVALID: 'Die vermögenswirksame Leistungen sind ungültig.',
  CARE_INSURANCE_CONTRIBUTION_INVALID: 'Der Pflegeversicherungsbeitrag ist ungültig.',
  CHILD_ALLOWANCE_INVALID: 'Der Kinderfreibetrag ist ungültig.',
  CHURCH_TAX_INVALID: 'Die Kirchensteuer ist ungültig.',
  CITY_INVALID: 'Die Stadt ist ungültig.',
  CITY_OF_BIRTH_INVALID: 'Der Geburtsort ist ungültig.',
  COMPANY_IDENTIFIER_INVALID: 'Die Unternehmens-ID ist ungültig.',
  COMPANY_NAME_INVALID: 'Der Unternehmensname ist ungültig.',
  COMPANY_NOT_DELETABLE: 'Das Unternehmen konnte nicht gelöscht werden.',
  COMPANY_NOT_FOUND: 'Das Unternehmen konnte nicht gefunden werden.',
  COMPANY_ONBOARDING_ERROR:
    'Die Firmeneintragung war nicht erfolgreich. Es existiert bereits ein Unternehmen mit dieser ID.',
  COMPANY_PLAN_NOT_FOUND: 'Der Unternehmensplan konnte nicht gefunden werden. ',
  CONFIGURATION_INVALID: 'Die Konfiguration ist ungültig.',
  CONFIGURATION_NOT_FOUND: 'Die Konfiguration konnte nicht gefunden werden.',
  CONTACT_METHOD_INVALID: 'Die Kontaktmethode ist ungültig.',
  CONTRACT_STATUS_INVALID: 'Der Vertragsstatus ist ungültig.',
  COUNTRY_CODE_INVALID: 'Der Länder-Code ist ungültig.',
  COUNTRY_INVALID: 'Das Land ist ungültig.',
  COUNTRY_OF_BIRTH_INVALID: 'Das Geburtsland ist ungültig.',
  DATE_OF_BIRTH_INVALID: 'Das Geburtsdatum ist ungültig.',
  DOCUMENT_TYPE_INVALID: 'Der Dokumententyp ist ungültig.',
  EMAIL_BUSINESS_INVALID: 'Die geschäftliche E-Mail-Adresse ist ungültig.',
  EMAIL_INVALID: 'Die E-Mail-Adresse ist ungültig.',
  EMAIL_PERSONAL_INVALID: 'Die persönliche E-Mail-Adresse ist ungültig.',
  EMPLOYEE_CITY_INVALID: 'Die Stadt ist ungültig.',
  EMPLOYEE_COUNTRY_INVALID: 'Das Land ist ungültig.',
  EMPLOYEE_CREATION_DUPLICATE: 'Dieser Arbeitnehmer existiert bereits.',
  EMPLOYEE_DATE_OF_BIRTH_INVALID: 'Das Geburtsdatum ist ungültig.',
  EMPLOYEE_IDENTIFIER_INVALID: 'Die Arbeitnehmer-ID ist ungültig.',
  EMPLOYEE_NOT_FOUND: 'Der Arbeitnehmer konnte nicht gefunden werden.',
  EMPLOYEE_PHONE_NUMBER_INVALID: 'Die Telefonnummer ist ungültig.',
  EMPLOYEE_POSTAL_CODE_INVALID: 'Die Postleitzahl ist ungültig.',
  EMPLOYEE_STATUS_INVALID: 'Der Arbeitnehmer-Status ist ungültig.',
  EMPLOYMENT_START_DATE_INVALID: 'Das Datum des Beschäftigungsbeginns ist ungültig.',
  ERROR_EXCEL_IMPORT_ACCESS_DENIED: 'Die Datei konnte aufgrund fehlender Zugriffsrechte nicht importiert werden.',
  ERROR_EXCEL_IMPORT_COMPANY_UNKNOWN:
    'Die Datei konnte aufgrund eines unbekannten Unternehmens nicht importiert werden.',
  ERROR_EXCEL_IMPORT_INVALID_COUNTRY: 'Die Datei konnte aufgrund einer ungültigen Stadt nicht importiert werden.',
  ERROR_EXCEL_IMPORT_INVALID_EMAIL:
    'Die Datei konnte aufgrund einer ungültigen E-Mail-Adresse nicht importiert werden.',
  ERROR_EXCEL_IMPORT_INVALID_LANGUAGE_PREFERENCE:
    'Die Datei konnte aufgrund einer ungültigen Sprach-Präferenz nicht importiert werden.',
  ERROR_EXCEL_IMPORT_INVALID_POSTAL_CODE:
    'Die Datei konnte aufgrund einer ungültigen Postleitzahl nicht importiert werden.',
  ERROR_EXCEL_IMPORT_INVALID_SALUTATION: 'Datei konnte aufgrund einer ungültigen Anrede nicht importiert werden.',
  ERROR_EXCEL_IMPORT_INVALID_STREET_ADDRESS:
    'Die Datei konnte aufgrund einer ungültigen Adresse nicht importiert werden.',
  ERROR_EXCEL_IMPORT_WRONG_FORMAT: 'Die Datei konnte aufgrund eines ungültigen Formats nicht importiert werden.',
  EXAMPLE_CALCULATION_NOT_FOUND: 'Die Beispielberechnung konnte nicht gefunden werden.',
  GROSS_INCOME_INVALID: 'Das Brutto-Einkommen ist ungültig.',
  HEALTH_INSURANCE_CONTRIBUTION_RATE_INVALID: 'Der Beitragssatz der Krankenversicherung ist ungültig.',
  HEALTH_INSURANCE_PRIVATE_CONTRIBUTION_INVALID: 'Der private Beitragssatz der Krankenversicherung ist ungültig.',
  HEALTH_INSURANCE_TYPE_INVALID: 'Die Krankenversicherung ist ungültig.',
  INCOME_TAX_CONTRIBUTION_INVALID: 'Der Einkommenssteuer-Beitrag ist ungültig.',
  LANGUAGE_CODE_INVALID: 'Der Sprach-Code ist ungültig.',
  LANGUAGE_FORM_INVALID: 'Die Sprach-Form ist ungültig.',
  LANGUAGE_PREFERENCE_INVALID: 'Die Sprach-Präferenz ist ungültig.',
  NATIONALITY_INVALID: 'Die Nationalität nicht verfügbar.',
  OCCUPATION_INVALID: 'Der Beruf ist ungültig.',
  ORGANIZATION_IDENTIFIER_INVALID: 'Die Organisation-ID ist ungütlig.',
  ORGANIZATION_NAME_INVALID: 'Der Organisationsname ist ungültig.',
  ORGANIZATION_NOT_FOUND: 'Die Organisation konnte nicht gefunden werden.',
  PERSONAL_NUMBER_INVALID: 'Die Personalnummer ist ungültig.',
  PLAN_DELETE_ERROR: 'Der Versicherungsplan konnte nicht gelöscht werden, da er einem Unternehmen zugewiesen ist.',
  PLAN_NOT_FOUND: 'Der Versicherungsplan konnte nicht gefunden werden.',
  POSTAL_CODE_INVALID: 'Die Postleitzahl ist ungültig.',
  PROVIDER_DELETE_ERROR: 'Der Anbieter konnte nicht gelöscht werden, da er Versicherungspläne enthält',
  PROVIDER_NOT_FOUND: 'Der Provider konnte nicht gefunden werden. Wahrscheinlich ist die ID falsch.',
  SALUTATION_INVALID: 'Die Anrede ist ungültig.',
  STREET_ADDRESS_INVALID: 'Die Addresse ist ungültig.',
  STREET_NUMBER_INVALID: 'Die Hausnummer ist ungültig.',
  TAX_CLASS_FACTOR_INVALID: 'Der Steuerklassen-Faktor ist ungültig.',
  TAX_CLASS_INVALID: 'Die Steuerklasse ist ungültig.',
  TAX_RESIDENCE_INVALID: 'Der Steuersitz ist ungültig.',
  CONTRACT_NOT_FOUND: 'Der Vertrag konnte nicht gefunden werden.',
  BENEFICIARY_TYPE_INVALID: 'Die bezugsberichtigte Person ist ungültig.',
  CONTRACT_STATUS_TRANSITION_INVALID: 'Dieser Statuswechsel ist ungültig.',
  EMPLOYEE_CONTRIBUTION_INVALID: 'Der Beitrag des Arbeitnehmers ist ungültig.',
  EMPLOYER_CONTRIBUTION_INVALID: 'Der Beitrag des Arbeitgebers ist ungültig.',
  MESSAGING_IDENTIFIER_INVALID: 'Die Benachrichtigungs-ID ist ungültig.',
  TOTAL_CONTRIBUTION_INVALID: 'Der Gesamtbeitrag ist ungültig.',
  CONSULTANT_IDENTIFIER_INVALID: 'Die Berater-ID ist ungültig.',
  CONSULTANT_IDENTIFIER_IDENTIFER: 'Die Berater-ID ist ungültig.',
  COMPANY_IDENTIFIER_IDENTIFER: 'Unternehmens-ID ist ungültig.',
  CONSULTANT_APPOINTMENT_SCHEDULING_LINK_INVALID: 'Der Link für den Beratungstermin ist ungültig.',
  CONSULTANT_DELETE_ERROR: 'Der Berater konnte nicht gelöscht werden.',
  CONSULTANT_NOT_FOUND: 'Der Berater konnte nicht gefunden werden.',
  DOCUMENT_FILE_NAME_INVALID: 'Der Name des Dokuments ist ungültig.',
  DOCUMENT_CONTENT_TYPE_INVALID: 'Der Inhalt des Dokuments ist ungültig.',
  DOCUMENT_SIZE_INVALID: 'Die Größe des Dokuments ist ungültig',
  DOCUMENT_CREATED_DATE_INVALID: 'Das Erstellungsdatum des Dokuments ist ungültig.',
  DOCUMENT_NOT_FOUND: 'Das Dokument konnte nicht gefunden werden.',
  COMPANY_DOCUMENT_MODIFY_NOT_SUPPORTED: 'Das Ändern von Unternehmensdokumenten ist nicht erlaubt.',
  IDENTIFIER_INVALID: 'Die ID ist ungültig.',
  DOCUMENT_CATEGORY_IDENTIFIER_INVALID: 'Die ID der Dokumenten-Kategorie ist ungültig.',
};
